import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "./AvatarSelector.css"; // Import custom styles

const AvatarSelector = (props) => {
  const [selectedAvatar, setSelectedAvatar] = useState(props.userImage || null);
  const [showModal, setShowModal] = useState(false);
  const [loadingAv, setLoadingAv] = useState(true);
  const [avatars, setAvatars] = useState([]);

  const handleChooseAvatar = () => {
    setShowModal(true);
  };

  const handleAvatarSelect = (avatarPath) => {
    setSelectedAvatar(avatarPath);
    setShowModal(false);
    props.onAvatarSelect(avatarPath);
  };

  useEffect(() => {
    // Load avatars
    const avatarPaths = [];
    let loadedAvatars = 0;
    for (let i = 1; i <= 30; i++) {
      const avatarPath = `/images/avatars/${i}.jpg`;
      const img = new Image();
      img.src = avatarPath;
      img.onload = () => {
        loadedAvatars++;
        avatarPaths.push(avatarPath);
        if (loadedAvatars === 30) {
          setLoadingAv(false);
          setAvatars(avatarPaths);
        }
      };
      img.onerror = () => {
        loadedAvatars++;
        if (loadedAvatars === 30) {
          setLoadingAv(false);
          setAvatars(avatarPaths);
        }
      };
    }
  }, []);

  return (
    <div className="d-flex flex-column align-items-center">
      <img
        className="img-fluid img-ellipse my-3"
        src={selectedAvatar || "/images/sampleUser.png"}
        alt="Selected Avatar"
      />
      <Button variant="primary" onClick={handleChooseAvatar}>
        {selectedAvatar?("Change Avatar"):("Choose Avatar")}
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="justify-content-center text-center">
          {loadingAv ? (
            <div className="text-center z-3">
              <Spinner animation="border" variant="primary" />
              <p>Loading avatars...</p>
            </div>
          ) : (
            <div className="d-flex flex-wrap justify-content-center">
              {avatars.map((avatar) => (
                <img
                  key={avatar}
                  className="avatar-img avatar-selectable mx-2 my-2"
                  src={avatar}
                  alt="Select Avatar"
                  onClick={() => handleAvatarSelect(avatar)}
                />
              ))}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AvatarSelector;
