import React from 'react'
import '../../assets/timeline.css'
// Importing the theme css files
import '../../assets/css/main-style.css'
import '../../assets/css/main-responsive.css'
import TimeLine from '../TimeLine/TimeLine'
import Exchanger from '../Exchanger/Exchanger'
import JotForm from '../JotForm/JotForm'

export default function MainComponent() {
  return (
    <>
    <main>
        {/* banner section */}
        <section className="banner-area" id="banner_animation">
          <div className="pt-50"></div>
          <div className="bubbles">
            <div data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: -250, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: -250, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 50, &quot;y&quot;: -250, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 60, &quot;y&quot;: -200, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 50, &quot;y&quot;: -130, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 130, &quot;y&quot;: 250, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: -250, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
            <div data-parallax="{&quot;x&quot;: 100, &quot;y&quot;: -250, &quot;rotateZ&quot;:0}">
              <div className="bubble">
              </div>
            </div>
          </div>
          <div className="logos">
            <img src="img/banner/Logo-1.png" alt="social" />
            <img src="img/banner/Logo-2.png" alt="social" />
            <img src="img/banner/Logo-3.png" alt="social" />
            <img src="img/banner/Logo-4.png" alt="social" />
            <img src="img/banner/Logo-5.png" alt="social" />
            <img src="img/banner/Logo-6.png" alt="social" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="banner-content text-center pt-200">
                  <h1>Send money to friends and family instantly</h1>
                  <div className="img-area mt-35">
                    <img className="img-fluid banner-img" data-depth="0.6" src="img/banner/guy-phone.png" alt="person"  />
                  </div>
                  <div className="symbol-pulse">
                    <div className="pulse-1" />
                    <div className="pulse-2" />
                    <div className="pulse-x" />
                  </div>
                </div>
              </div>
            </div>
            {/* statistics area */}
            <div className="row position-relative">
              <div className="col-12">
                <div className="floated-widget bg-gradient-theme box-shadow-1">
                  {/* <div className="row text-center gy-2 gy-lg-0">
                    <div className="col-lg-3 col-6">
                      <div className="statistics-widget-1 text-start pt-10">
                        <img src="img/banner/icon-1.svg" alt="icon" />
                        <p>Active user</p>
                        <h2 className="counter"><span>15,000</span></h2>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6">
                      <div className="statistics-widget-1 text-start pt-10">
                        <img src="img/banner/icon-2.svg" alt="icon" />
                        <p>download</p>
                        <h2 className="counter"><span>49.9</span>k</h2>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6">
                      <div className="statistics-widget-1 text-start pt-10">
                        <img src="img/banner/icon-3.svg" alt="icon" />
                        <p>reviews</p>
                        <h2 className="counter"><span>35.7</span>k</h2>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6">
                      <div className="statistics-widget-1 text-start pt-10">
                        <img src="img/banner/icon-4.svg" alt="icon" />
                        <p>partners</p>
                        <h2 className="counter"><span>199</span>+</h2>
                      </div>
                    </div>
                  </div> */}
                  <Exchanger/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* banner section end*/}
        {/* Jot form Section begins */}
        
        {/* feature section */}
        <section id="feature" className="feature-area pt-120 pb-130">
          <div className="container pt-160 pt-lg-4 ">
            <div className="row text-center">
              <div className="py-4 ">
                <JotForm />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mx-auto pt-lg-4 pb-65">
                <div className="section-title">
                  <h2 className="wow fadeInUp">Move Funds</h2>
                  <p className="wow fadeInUp" data-wow-delay="0.3s">Transfer money across borders without traditional
                   banking intermediaries or restriction on currencies.</p>
                </div>
              </div>
            </div>
            <div className="row gy-xl-0 gy-4">
            <div className="col-xl-3 col-md-6">
                <div className="feature-card-widget wow fadeInUp" data-wow-delay="0.6s">
                  <div className="card-img">
                    <img src="img/feature/icon-3.svg" alt="feature svg" />
                  </div>
                  <h4>Send</h4>
                  <p>Send money to loved ones abroad in 3 easy steps.</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-card-widget wow fadeInUp">
                  <div className="card-img">
                    <img src="img/feature/icon-1.svg" alt="feature svg" />
                  </div>
                  <h4>Transfers</h4>
                  <p>No hidden fees and affordable rates.</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="feature-card-widget wow fadeInUp" data-wow-delay="0.3s">
                  <div className="card-img">
                    <img src="img/feature/icon-2.svg" alt="feature svg" />
                  </div>
                  <h4>Receive</h4>
                  <p>Receive money instantly from friends and family abroad.</p>
                </div>
              </div>
              
              <div className="col-xl-3 col-md-6">
                <div className="feature-card-widget wow fadeInUp" data-wow-delay="0.9s">
                  <div className="card-img">
                    <img src="img/feature/icon-4.svg" alt="feature svg" />
                  </div>
                  <h4>Pay</h4>
                  <p>Pay school fees, bills and more at affordable rates.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* feature section end*/}
        {/* track financial progress section */}
        <section className="track-f-progress pt-125 pb-160">
          <div className="container">
            <div className="row gy-lg-0 gy-4 pb-10">
              <div className="col-md-6 mx-auto">
                <div className="img-content text-center">
                  <img className="bg-img" src="img/track-progress/shape-bg.png" alt="" />
                  <img className="img-fluid track-1 wow fadeInRight" data-wow-delay="0.1s" src="img/track-progress/friends.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-5 offset-xl-1 d-flex align-items-center">
                <div className="text-content">
                  <h2>Transfers</h2>
                  <p>
                  <i className="fas fa-check-circle mx-2"></i> Live exchange rates.<br />
                  <i className="fas fa-check-circle mx-2"></i> No extra fees. <br />
                  <i className="fas fa-check-circle mx-2"></i> Fast transfers . <br />
                  <i className="fas fa-check-circle mx-2"></i> Use your money instantly.

                  </p>
                  <div>
                    <a className="theme-btn-2 mt-55" href="/">
                      <span className="arrow">
                        <span className="horizontal-line" />
                      </span>SIGN UP NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* track financial progress section end*/}

        {/* timeline section starts here */}
        <div className="container-fluid bg-light">
        <h2 className='text-center text-dark py-4'>Funds Transfered in 3 Simple Steps</h2>
        <section id="timeline" className="py-4 d-none d-md-block">
        <article className="animate__animated animate__bounce">
          <div className="inner">
            <span className="dot" >
              <span className="inner-dot mx-auto " />
            </span>
            <h2 className="d-block d-md-none font-small">1. <i className="fas fa-user-plus mx-2" /> Sign up: Create a free CendMate account.</h2>
            <h2 className="d-none d-md-block">1. <i className="fas fa-user-plus mx-2" /> Sign up: Create a free CendMate account.</h2>
            {/* add extra space to timeline in small */}
            <div className="py-4 my-2 d-md-none " />
            <div className="py-4  d-none d-md-block " />
          </div>
        </article> 
        <article>
          <div className="inner">
            <span className="dot" >
              <span className="inner-dot mx-auto " />
            </span>
            <h2 className="d-block d-md-none font-small">2. <i className="fas fa-id-card mx-2" /> Get Verified:Upload And Verify your identity.</h2>
            <h2 className="d-none d-md-block">2. <i className="fas fa-id-card  mx-2" />Get Verified:Upload And Verify your identity.</h2>
            {/* add extra space to timeline in small */}
            <div className="py-4 my-2 d-md-none" />
          </div>
        </article>
        <article>
          <div className="inner">
            <span className="dot" >
              <span className="inner-dot mx-auto" />
            </span>
            <h2 className="d-md-none font-small">3. <i className="fas fa-wallet mx-2" />Transfer: Fund your wallet via Interac and transfer.</h2>
            <h2 className="d-none d-md-block">3. <i className="fas fa-wallet mx-2" />Transfer: Fund your wallet via Interac and transfer.</h2>
            {/* add extra space to timeline in small */}
            <div className="py-4 my-2 d-md-none" />
            <div className="py-2  d-none d-md-block " />
          </div>
        </article>
        
        </section>
        {/* for mobile devices */}
        <section className='d-md-none py-2'>
          <TimeLine/>
        </section>
        </div>

        
        
        {/* what client says section */}
        <section className="client-area pt-110 ">
          <div className="container">
            <div className="section-title">
              <h2 className=" mb-0">What Our Clients Say</h2>
            </div>
            <div className="client-slider pt-45 pb-140">
              <div className="row justify-content-center">
                <div className="single-client  col-md-3" data-wow-delay="0.1s">
                  <img className="img-fluid  img-ellipse-sm rounded-circle" src="/images/avatars/18.jpg" alt="cleint" />
                  <p className="quote">CendMate is very reliable, fast and highly recommended.</p>
                  <div className="client-info">
                    <div>
                      <p>Robert Nosa</p>
                      <span className="role">Winnipeg, MB</span>
                    </div>
                    <div className="rating">
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                    </div>
                  </div>
                </div>
                <div className="single-client  col-md-3" data-wow-delay="0.3s">
                  <img className="img-fluid img-ellipse-sm rounded-circle" src="/images/avatars/23.jpg" alt="cleint" />
                  <p className="quote">I sent money using CendMate, it was incredibly fast and convenient.</p>
                  <div className="client-info">
                    <div>
                      <p>Iyobosa Idehen</p>
                      <span className="role">Burnaby, BC</span>
                    </div>
                    <div className="rating">
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                    </div>
                  </div>
                </div>
                <div className="single-client  col-md-3" data-wow-delay="0.5s">
                  <img className="img-fluid img-ellipse-sm rounded-circle" src="/images/avatars/20.jpg" alt="cleint" />
                  <p className="quote">CendMate is efficient and easy to use, I referred some friends. </p>
                  <div className="client-info">
                    <div>
                      <p>Uzoma Azikiwe</p>
                      <span className="role">Vancouver, BC</span>
                    </div>
                    <div className="rating">
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                      <a href="/"><i className="icon_star" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* call to action area */}
            <div className="row pt-110">
              <div className="col-md-12 position-relative">
                <div className="cta cta-2" >
                  <div className="bubbles">
                    <div className="bubble-1" />
                    <div className="bubble-2" />
                    <div className="bubble-3" />
                    <div className="bubble-4" />
                    <div className="bubble-5" />
                    <div className="bubble-6" />
                    <div className="bubble-7" />
                    <div className="bubble-8" />
                  </div>
                  <div className="row gy-xl-0 gy-4">
                    <div className="col-xl-5">
                      <div className="cta-content wow fadeInLeft text-center text-xl-start">
                        <h2>Download Our Free Mobile App</h2>
                      </div>
                    </div>
                    <div className="col-xl-7 d-flex align-items-center flex-wrap justify-content-xl-end justify-content-center">
                      <a href="/">
                        <div className="app-btn mt-3 mt-sm-0 wow fadeInRight" data-wow-delay="0.1s">
                          <i className="fab fa-google-play" />
                          <div className="btn-text">
                            <span>GET IT ON</span>
                            <p>Google Play</p>
                          </div>
                        </div>
                      </a>
                      <a href="/">
                        <div className="app-btn mt-3 mt-sm-0 wow fadeInRight" data-wow-delay="0.2s">
                          <i className="fab fa-apple" />
                          <div className="btn-text">
                            <span>Downloan on the</span>
                            <p>Apple Store</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* what client says section end */}
      </main>
    </>
  )
}
