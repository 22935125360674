import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import '../assets/convert.css'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Select from 'react-select'
import { getExchangeDetails } from '../actions/exchangeAction'

const Convert = ({ location, history }) => {

  const [fromWallet, setFromWallet] = useState('')
  const [toWallet, setToWallet] = useState('')
  const [amount, setAmount] = useState(0)
  const [wallets, setWallets] = useState([{_id:0, currency:'CAD'}, {_id:0, currency:'NGN'}, {_id:0, currency:'INR'} ])
  const dispatch = useDispatch()

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo } = userVerify

  const exchangeDetails = useSelector((state) => state.exchangeDetails)
  const { loading: loadingRate, error:errorRate, exchange } = exchangeDetails

  useEffect(() => {
    dispatch(getExchangeDetails('',''))
    if (userInfo && userInfo !== null) {
        // list UserDetails or action
      } else {
        history.push('/login')
      }
  }, [dispatch, history, userInfo])

// const wallets = [{_id:0, currency:'CAD'}, {_id:0, currency:'NGN'}, {_id:0, currency:'INR'} ]

//generate options for filament selector 
const generateWalletOptions=()=>{
  return  wallets.map((wallet)=>{
    return {
      value:wallet.currency,
      label:wallet.currency,
      _id:wallet._id
    }
  })
}

 //styles for select
 const customStyles = {
    
} 

  return (
    <div className="pt-100 h-100">
      {loading || loadingRate ? (
      <Loader />
    ) : error || loadingRate ? (
      <Message variant='danger'>{error}</Message>
    ) : userInfo && userInfo !=null && exchange.success ? (
      <div className="container-fluid my-auto">
        <div className="row">
          <div className="h4 font-poppins">Convert Currency Page</div>
        </div>
        <div className="row justify-content-center pt-4 h-100" >
            <div className="col-md-5 ">
                <div className="form-card  p-4">
                    <form action="">
                    <Form.Group controlId='wallet'>
                        <Form.Label>From Wallet</Form.Label>
                        <Select 
                          styles={customStyles} 
                          options={generateWalletOptions()} 
                          value={fromWallet} 
                            onChange={(selectedOption) => {
                              setFromWallet(selectedOption)
                              //also reset the To Wallet List if it is the same 
                              if(selectedOption.value === toWallet.value){
                                setToWallet('')
                              }
                              //check the conversion rate if toWallet has value
                              if(toWallet.value){
                                dispatch(getExchangeDetails(selectedOption.value,toWallet.value))
                              }
                            }
                            
                        }
                          />
                        </Form.Group>
                        <div className="form-group">
                            <label for="amountInput">Amount</label>
                            <input type="number" className="form-control" id="amountInput" placeholder="Enter the Amount to Convert" value={amount} onChange={(e) => setAmount(e.target.value)} disabled={toWallet ==='' || fromWallet === ''} />
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-md-2 my-auto text-center ">
              <i class="fas fa-arrow-right fa-3x text-primary" ></i>
              <div className="lead font-small font-weight-bold py-2">Conversion Rate</div>
              {exchange.info.rate && fromWallet.value && toWallet.value?(<div className="lead font-small">{"1 "+fromWallet.value+" = "+exchange.info.rate + " "+ toWallet.value}</div>):(<div className="lead font-small  py-2">Please Select the Wallets to transfer from</div>)}
              
            </div>
            <div className="col-md-5 ">
                <div className="form-card  p-4">
                    <form action="">
                        <Form.Group controlId='wallet'>
                        <Form.Label>To Wallet</Form.Label>
                        <Select 
                          styles={customStyles} 
                          options={generateWalletOptions()} 
                          value={toWallet} 
                            onChange={(selectedOption) => {
                              setToWallet(selectedOption)
                              //also reset the From Wallet List if it is the same 
                              if(selectedOption.value === fromWallet.value){
                                setFromWallet('')
                              }
                              if(fromWallet.value){
                                dispatch(getExchangeDetails(fromWallet.value,selectedOption.value))
                              }
                            }
                            
                        }
                          />
                        </Form.Group>
                        <div className="form-group">
                            <label for="amountInput">Converted Amount</label>
                            <input type="number" className="form-control" id="amountInput" placeholder="Calculated Amount" value={(amount*exchange.info.rate).toFixed(2)} disabled />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="row justify-content-center py-4">
          <div className="col-2">
            <div className="btn btn-primary btn-block">Convert</div>
          </div>
        </div>
      </div>
    ):(
      <div className="lead">Sorry You are Not Authorized, Please log out and log back in!</div>
    )}
    </div>
  )
}

export default Convert
