import React from 'react'
import MainComponent from '../components/MainScreen/MainComponent'

export default function MainScreen() {
  return (
    <>
    <div className="mt-50-">
      <MainComponent/>
    </div>
    </>
  )
}
