import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/userActions'
import { useIdleTimer } from 'react-idle-timer';
import { Modal, Button } from 'react-bootstrap';

const timeout = 500000;
const promptBeforeIdle = 40000;

const IdleTimer = ({children})=> {
  const dispatch = useDispatch()

  const userVerify = useSelector((state) => state.userVerify)
  const { userInfo } = userVerify

  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setState('Idle');
    setOpen(false);
    dispatch(logout());
  };

  const onActive = () => {
    console.log('Active')
    setState('Active');
    setOpen(false);
  };

  const onPrompt = () => {
    setState('Prompted');
    setOpen(true);
  };

  const { start , getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    startManually:true,
    stopOnIdle:true,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    if(userInfo && userInfo._id){
        start();
    }
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, userInfo]);

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

  return (
    <>
      <Modal show={open} onHide={() => onIdle()}>
        <Modal.Header >
          <Modal.Title>Are you still here?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Logging out in {remaining} seconds</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onIdle()}>
            Log Out
          </Button>
          <Button variant="primary" onClick={handleStillHere}>
            I'm still here
          </Button>
        </Modal.Footer>
      </Modal>
      {children}
    </>
  );
}

export default IdleTimer;
