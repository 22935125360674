import React from "react";
import "./style.css";

export default function AMLpolicy() {
  return (
    <div id="page">
      <section className="banner-area pt-100"></section>
      <div className="container pt-25">
        <div className="text-center h2">Cendmate</div>
        <div className="text-center h4">KYC/AML/CTF Policy</div>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>
            Know Your Customer [KYL], Anti-Money Laundering [AML] &amp; Counter
            Terrorist Funding [CTF]
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>AML &amp; CTF Basics</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          Money laundering involves taking money obtained by committing a crime
          and disguising the source to make it appear legitimate. Under the
          Criminal Code of Canada, it is illegal to launder money or knowingly
          assist in laundering money. The PCMLTFA and subsequent international
          regulatory boards require us to take appropriate measures to prevent
          criminal activity. According to the law, as a money service business,
          we must report all suspicious funds. &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          Terrorist financing is the movement of funds to pay for terrorist
          activities. Although the source of the funds is not always criminal,
          the use of funds tends to be. The Criminal Code of Canada states the
          following, it is illegal to knowingly assist in the financing of
          terrorism and to have terrorist funds or property. All known or
          suspected accumulation of terrorist properties in our possession must
          be reported immediately. CendMate operates within Canada and does not
          interact with countries listed as “Terrorist Friendly” by FINTRAC.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>CendMate’s Commitment&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          CendMate reports to and follows the guidelines of the Financial
          Transactions and Reports Analysis Centre of Canada (FINTRAC). CendMate
          is committed to preventing, detecting, and deterring money laundering
          and terrorist financing. We have constructed policies to report
          standards and obligations, facilitate efficient record keeping,
          conduct extensive information collection, monitor staff
          responsibilities, and provide appropriate staff training.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          It is the responsibility of every employee (including contract and
          part-time employees) to comply with this program and all related
          Canadian legislation.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          FINTRAC is a Canadian federal initiative that regulates our industry
          to ensure that we are meeting our obligations. They have the power to
          review our documentation and to levy significant penalties if we are
          not compliant. Individuals that deliberately attempt to circumvent the
          law may also be charged criminally in addition to monetary penalties.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>KYC Policy</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          CendMate must verify the identity of customers for certain activities
          and transactions according to the Proceeds of Crime (Money Laundering)
          and Terrorist Financing Regulations (PCMLTFR). CendMate can confirm
          the identity of a customer by relying on an identity document where it
          is “valid and authentic.” We can confirm identification using
          acceptable documents, presented in an electronic format, so long as
          they can be authenticated. The individual's information is collected,
          and any suspicious transaction is reported to FINTRAC and further
          investigated by our compliance team. We refuse any transaction where
          required documents are not provided.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>
            The Identification document must fit the following requirements:
          </strong>
        </p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Issued by a provincial, territorial or federal government in Canada
            or an equivalent foreign government; Valid (not expired)
          </li>
          <li>
            Bears a unique identifier number (such as a driver’s license number)
          </li>
          <li>Bears the name of the individual being identified</li>
          <li>Bears a photo of the individual being identified.</li>
        </ul>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "36.0pt",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>
            We must also collect and record the following information:
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <ul style={{ listStyleType: "disc", marginLeft: 8 }}>
          <li>
            Customer full name (no initials, short forms, or abbreviations);
          </li>
          <li>
            Customer’s occupation (this should be as detailed as possible,
            without abbreviations or acronyms)
          </li>
          <li>
            The purpose of our business relationship with the customer (if
            applicable);
          </li>
          <li>
            Customer’s date of birth (if this appears on the identification
            document, the date of birth that we record must match the document);
          </li>
          <li>
            Complete home address (post boxes, office addresses, and general
            delivery addresses are not acceptable for this purpose; if the
            customer wishes to provide a separate mailing address, we can
            collect this as well, but we must always record the full home
            address);
          </li>
          <li>The type of document used to identify the customer</li>
          <li>
            Place that the identification was issued (province, territory or
            state and country, not a city);
          </li>
          <li>
            Unique identification number (such as the driver’s license number);
          </li>
          <li>
            Expiry date of the identification provided (if the document has an
            expiry date; if it does not, please make a note to say that there
            was no expiry date on the document); &nbsp;&nbsp;
          </li>
          <li>The date on which we verified the document.</li>
        </ul>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          If a customer has been identified previously and the information in
          our records is up to date (the identification document used has not
          expired), and we can verify the customer's identity, then we do not
          need to request identification to complete a transaction.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>Anti-Fraud Initiative&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          In addition to ensuring our customers are verified via our KYC
          procedures, CendMate actively works to identify and cease fraudulent
          activity. If we suspect a customer is transacting under fraudulent
          pretenses, we contact that individual, investigate their reasoning for
          the purchase, and deny the transaction if it is ruled to be fraudulent
          in origin and report the transaction to FINTRAC.
        </p>
      </div>
    </div>
  );
}
