import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header/Header'
import Footer from './components/Footer'
import LoginScreen from './screens/LoginScreen'
import VerifyScreen from './screens/VerifyScreen'
import TermsAndConditions from './screens/TermsAndCondition/TermsAndConditions'
import Dashboard from './screens/Dashboard/Dashboard'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import MainScreen from './screens/MainScreen'
import Convert from './screens/Convert'
import ComplyApp from './screens/ComplyApp'
import ComplyVerify from './screens/ComplyVerify'
import UserProfileScreen from './screens/UserProfileScreen'
import UserRegistration from './screens/UserRegistrationScreen'
import Exchanger from './components/Exchanger/Exchanger'
import Login from './screens/Login/Login'
import OpenWalletScreen from './screens/OpenWalletScreen'
import WalletScreen from './screens/WalletScreen/WalletScreen'
import IdleTimer from './components/IdleTimer/IdleTimer'
import ExchangePage from './screens/ExchangePage/ExchangePage'
import PrivacyPolicy from './screens/TermsAndCondition/PrivacyPolicy'
import AMLpolicy from './screens/TermsAndCondition/AMLpolicy'
import ResetPassword from './screens/ResetPassword/ResetPassword'


const App = () => {
  return (
    <Router>
      <Header />
      <main>
        <div>
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/terms-and-conditions' component={TermsAndConditions} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/aml-policy' component={AMLpolicy} />
          <Route path='/login' component={Login} />
          <Route path='/register' component={UserRegistration} />
          <Route path='/resetpassword' component = {ResetPassword} />
          <Route path='/verify' component={VerifyScreen} />
          <Route path='/kyc' component={ComplyApp} />
          <Route path='/kycOld' component={ComplyVerify} />
          <Route path='/profile' component={UserProfileScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/exchange' component={ExchangePage} />
          <Route path='/convert' component={Convert} />
          <Route path='/registerWallet' component={OpenWalletScreen}  />
          <Route path='/wallet/:id' component={WalletScreen} />
          <Route path='/timer' component={IdleTimer} />
        </div>

        <Container fluid className='px-0'>
        <Route path='/' component={MainScreen} exact />
        </Container>
        
      </main>
      <Footer/>
    </Router>
  )
}

export default App
