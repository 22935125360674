

import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import './TransactionTable.css';
import Loader from '../../components/Loader'; // Import the Loader component
import * as transactionsService from '../../services/transactionService'; // Import your transactionsService functions
import { addCommasToNumber, convertTimestampToFullDateTimeWithTimezone } from '../../utility/utilityFunctions'; // Import the addCommasToNumber function

const TransactionTable = ({ type, userToken, userId, walletId, showAll }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchTransactions() {
      try {
        let fetchedTransactions = [];

        if (type === 'wallet') {
          // Fetch wallet-specific transactions using walletId and userToken
          fetchedTransactions = await transactionsService.getWalletTransactions(walletId, userToken);
        } else if (type === 'user') {
          // Fetch user-specific transactions using userToken
          fetchedTransactions = await transactionsService.getUserTransactions(userToken);
        } else if (type === 'all') {
          // Fetch all transactions (admin only) using userToken
          fetchedTransactions = await transactionsService.getAllTransactions(userToken);
        }

        setTransactions(fetchedTransactions);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        setError(error.response ? error.response.data.message : 'An error occurred');
        setLoading(false); // Set loading to false on error
      }
    }

    fetchTransactions();
  }, [type, userToken, userId, walletId]);

  return (
    <div className="container">
      {loading ? ( // Display loader while loading
        <Loader />
      ) : error ? ( // Display error if an error occurred
        <div className="alert alert-danger">{error}</div>
      ) : transactions.length > 0 ? (
        transactions
        .slice(0, showAll ? transactions.length : 5)
        .map((transaction) => (
          <div key={transaction._id} className="row transaction-row mb-2 rounded">
        <div className="col-8">
          <div className="d-flex flex-column justify-content-center align-items-start">
            <div className="d-flex flex-row align-items-center">
              <div className="transaction-type">
                {transaction.type === 'pay-in' || transaction.type==='deposit' ? (
                  <i className="fa-2x fas fa-arrow-circle-down m-2 text-success"></i>
                ) : transaction.type === 'pay-out' || transaction.type === 'transfer' ? (
                  <i className="fa-2x fas fa-arrow-circle-up m-2 text-danger"></i>
                ) : (
                  <i className="fa-2x fas fa-arrows-alt-h m-2 text-primary"></i>
                )}
              </div>
              <div className="detail-col mx-2">
                <div className="customer-name">
                  {`${transaction.user.fName} ${transaction.user.lName}`}
                </div>
                <span>{`Created on ${convertTimestampToFullDateTimeWithTimezone(transaction.createdAt)}`}</span>
                <div className="fw-bold text-small">Transaction Id: {transaction.referenceId}</div>
                <Badge pill className={(transaction.status==="completed" || transaction.status==="success") ?"bg-success":transaction.status==="failed"?"bg-danger":"bg-warning"}>
                    <span style={{ fontSize: '12px', textTransform:'capitalize' }}>{transaction.status}</span>
                </Badge>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 my-auto">
          <div className="d-flex flex-column justify-content-center align-items-end">
            <div className="transaction-amount">
              <div className="transaction-amount-bold d-flex flex-row justify-content-end">
                <div className="currency">
                  <Badge pill variant="light">
                    <span style={{ fontSize: '12px' }}>{transaction.currency}</span>
                  </Badge>
                </div>
                <div className="mx-2">{addCommasToNumber(transaction.amount)}</div>
              </div>
              {transaction.type === 'transfer' ? (
                <div className="transaction-amount-muted">{`${transaction.amountToSettle} ${transaction.currency} successfully transferred`}</div>
              ): null}
            </div>
          </div>
        </div>
        </div>
        )
      ) ) : (
        <div className="h5">No transactions to view yet!</div>
      )}
    </div>
  );
};

export default TransactionTable;
