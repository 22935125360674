import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ProgressBar, Alert } from 'react-bootstrap';
import Loader from '../Loader';
import Select from 'react-select';
import { createTransfer, listBanks } from '../../services/mapleRadServices';
import { initiateWithdraw } from '../../services/paymentService';
import { resolveAccount } from '../../services/mapleRadServices';

const WithdrawModalNGN = ({ show, onHide, onWithdraw, loadingWithdraw, error, currency, userToken, wallet }) => {
  const [alertText, setAlertText] = useState('');
  const [phase, setPhase] = useState(1);
  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [recipient, setRecipient] = useState({ provider: '', providerName: '' });
  const [confirmed, setConfirmed] = useState(false);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [institutionLoading, setInstitutionLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const [withdrawError, setWithdrawError] = useState(null);
  const [resolving, setResolving] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [withdrawResult, setWithdrawResult] = useState(null);

  useEffect(() => {
    if (show) {
      // Reset the state when the modal is shown
      setPhase(1);
      setAmount('');
      setRecipient({ phoneNumber: '', provider: '' });
      setConfirmed(false);
      setInstitutionOptions([]);
      setInstitutionLoading(false);
      setWithdrawLoading(false);
      setWithdrawSuccess(false);
      setWithdrawError(null);
      setResolving(false);
      setAccountName('');
    }
  }, [show]);

  const handleNext = () => {
    if (phase === 1 && amount) {
      setPhase(2);
      setInstitutionLoading(true);
      listBanks(userToken)
        .then((options) => {
          setInstitutionOptions(options);
          setInstitutionLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching provider list:', error);
          setInstitutionLoading(false);
        });
    } else if (phase === 2) {
      if (accountNumber && recipient.provider) {
        setPhase(3);
        setResolving(true);
        resolveAccount(userToken, accountNumber, recipient.provider)
          .then(({ accountName, error: resolveError }) => {
            if (resolveError) {
              setResolving(false);
              setWithdrawError(resolveError);
              setConfirmed(false);
            } else {
              setAccountName(accountName);
              setResolving(false);
            }
          });
      } else {
        setAlertText('All fields are required for this step');
      }
    }
  };

  const handleWithdraw = async () => {
    if (confirmed) {
      setWithdrawLoading(true);
      try {
        const result = await createTransfer(userToken, accountNumber, recipient.provider, parseFloat(amount), wallet, reason);
        setWithdrawSuccess(true);
        console.log(result)
        setWithdrawResult(`Use the following Reference Id to track your transaction:${result.transaction.id}`); // Set the withdrawal result
      } catch (error) {
        console.error('Withdrawal failed:', error);
        setWithdrawError(error.response.data.error || 'Withdrawal failed');
      } finally {
        setWithdrawLoading(false);
      }
    }
  };
  

  if (currency !== 'GHS' && currency !== 'KES' && currency !== 'NGN') {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Unsupported Currency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Withdrawals are only supported for GHS (Ghanaian Cedi) and KES (Kenyan Shilling).</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const transferModal = (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create a NGN Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertText.length > 0 && <Alert variant='danger'>{alertText}</Alert>}
        <ProgressBar now={(phase / 3) * 100} label={`Step ${phase} of 3`} variant="info" className="mb-3" />
        {withdrawLoading ? (
          <Loader />
        ) : withdrawError ? (
          <Alert variant="danger">
            <strong>Error:</strong> {withdrawError}
          </Alert>
        ) : phase === 1 ? (
          <Form>
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              {parseFloat(amount) + 20 > wallet.amount && (
                <Alert variant="danger">
                  <strong>Warning:</strong> Insufficient funds for withdrawal. Please enter a lower amount.
                </Alert>
              )}
            </Form.Group>
            <Form.Group>
            <Form.Label>Reason</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter comment for the transfer"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        ) : phase === 2 ? (
          <Form>
            <Form.Group controlId="provider">
              <Form.Label>Institution</Form.Label>
              {institutionLoading ? (
                <Loader />
              ) : (
                <Select
                  options={institutionOptions}
                  value={institutionOptions.find((option) => option.value === recipient.provider)}
                  onChange={(selectedOption) => setRecipient({ ...recipient, provider: selectedOption.value, providerName: selectedOption.label })}
                  placeholder="Select Institution"
                  isSearchable={true}
                />
              )}
            </Form.Group>
            <Form.Group controlId="accountNumber">
              <Form.Label>Recipient's Account Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter accountNumber"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </Form.Group>
          </Form>
        ) : (
          <div>
            {resolving ? (
              <Loader />
            ) : (
              <>
                <p>
                  <strong>Withdraw Amount:</strong> {amount} {currency}
                </p>
                <p>
                  <strong>Recipient's Account Number:</strong> {accountNumber}
                </p>
                <p>
                  <strong>Institution:</strong> {recipient.providerName}
                </p>
                {accountName && (
                  <p>
                    <strong>Account Name:</strong> {accountName}
                  </p>
                )}
                {/* Display checkbox only if the WithdrawResults is null */}
                {!withdrawResult && (
                  <Form.Group controlId="confirmCheckbox" className="mt-3 align-items-center">
                  <Form.Check
                    type="checkbox"
                    label="I confirm that I want to proceed with this withdrawal."
                    checked={confirmed}
                    onChange={() => setConfirmed(!confirmed)}
                  />
                </Form.Group>
                )}

                {/* Displaying success alert with withdrawal result */}
                {withdrawSuccess && withdrawResult && (
                  <Alert variant="success">
                    <strong>Success:</strong> {withdrawResult}
                  </Alert>
                )}
              </>
            )}
          </div>
        )}
        {phase !== 3 && (
          <Button variant="primary" onClick={handleNext} className="mt-3" disabled={parseFloat(amount) + 20 > wallet.amount}>
            Next
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        {loadingWithdraw || phase === 1 || withdrawError ? (
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        ) : phase === 2 ? (
          <Button variant="secondary" onClick={() => setPhase(1)}>
            Back
          </Button>
        ) : (
          <>
            {withdrawLoading ? (
              <>
                <Button variant="secondary" onClick={() => setPhase(2)} disabled={true}>
                  Back
                </Button>
                <Button variant="primary" onClick={handleWithdraw} disabled={true}>
                  Withdraw
                </Button>
              </>
            ) : withdrawSuccess ? (
              <Button variant="secondary" onClick={() => onHide()}>
                Close
              </Button>
            ) : (
              <>
                <Button variant="secondary" onClick={() => setPhase(2)}>
                  Back
                </Button>
                <Button variant="primary" onClick={handleWithdraw} disabled={!confirmed || resolving}>
                  Withdraw
                </Button>
              </>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {transferModal}
    </>
  );
};

export default WithdrawModalNGN;
