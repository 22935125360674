import React from "react";
import "./style.css";

export default function PrivacyPolicy() {
  return (
    <div id="page">
      <section className="banner-area pt-100"></section>
      <div className="container pt-25">
        <div className="text-center h2">Cendmate</div>
        <div className="text-center h4">Privacy Policy</div>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>1.) Introduction:</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          This Privacy Policy (the “Policy”) represents the policy of CendMate
          Inc., a Canadian corporation &nbsp; &nbsp;regarding the collection,
          use, and management of personal data belonging to:
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <ul style={{ listStyleType: "disc", marginLeft: 8 }}>
          <li>
            All visitors (“Site Visitors”) to the Company’s website, located at
            https://www.cendmate.com/ (the “Website”).
          </li>
          <li>
            Anyone who downloads or uses (“Application Users”) the Company’s
            mobile application (the “Application”).
          </li>
          <li>
            Anyone who enrolls for the Services of the Company (“Clients”),
            including Site Visitors, Application Users, and Clients are referred
            to collectively as “Users”. The website and the application together
            with the Services (as defined below) and all content contained
            therein are referred to collectively as the “Resources” as further
            described in the User Agreement.
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          To ensure the free, informed, and unambiguous consent of our Users to
          the collection and use of personal data, the Company requires all
          Users to read this Policy and accept the terms and conditions
          contained herein, together with consent to release personal data
          subject to such terms and conditions.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>2.) Collect Data</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>a. Contact Information:</strong>
        </p>
        <div
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <ul
            style={{
              marginBottom: "0cm",
              listStyleType: "disc",
              marginLeft: 8,
            }}
          >
            <li
              style={{
                margin: "0cm",
                fontSize: 16,
                fontFamily: '"Calibri",sans-serif',
              }}
            >
              Your full legal name, telephone number, location, postal address,
              e-mail address, date of birth, means of identification,
              occupation, personal description and photograph. From time to
              time, CendMate may require you and/or your beneficiary to provide
              us with additional information and/or documentation to confirm
              your identity and your eligibility to use CendMate’s service.
            </li>
          </ul>
        </div>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "18.0pt",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>b. Registration Information:</strong>
        </p>
        <div
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <ul
            style={{
              marginBottom: "0cm",
              listStyleType: "disc",
              marginLeft: 8,
            }}
          >
            <li
              style={{
                margin: "0cm",
                fontSize: 16,
                fontFamily: '"Calibri",sans-serif',
              }}
            >
              Username and password, reminder questions and answers, account
              numbers, and communication preferences such as which newsletters
              you would like to receive (when you provide that information to
              us).
            </li>
          </ul>
        </div>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "18.0pt",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>c. Payment Information:&nbsp;</strong>
        </p>
        <div
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <ul
            style={{
              marginBottom: "0cm",
              listStyleType: "disc",
              marginLeft: 8,
            }}
          >
            <li
              style={{
                margin: "0cm",
                fontSize: 16,
                fontFamily: '"Calibri",sans-serif',
              }}
            >
              Credit card or bank account number, expiration date, and card
              verification number (when you provide that information to us).
              Information about your Beneficiary, which may include sensitive
              personal information such as your Beneficiary's name, address,
              mobile phone number and/or bank account information.
            </li>
          </ul>
        </div>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "18.0pt",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>d. Online Identifiers:</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          IP address, advertising ID, unique device ID, other information about
          your device, and internal and third-party IDs that have been assigned
          to you.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>3. Data usage and processing:</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          We collect and use personal data only where we have one or more lawful
          grounds for doing so. Such grounds may vary depending on where our
          Users are located, but generally include processing personal data:
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>a.) To provide requested services and features:</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          To provide our Services, we must collect and use certain personal
          data. This includes:
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <ul style={{ listStyleType: "disc", marginLeft: 8 }}>
          <li>
            User profile data, which we use to establish and maintain User
            accounts; verify User identity; communicate with Users about their
            orders and accounts; and enable Users to make or receive payments
            (as and if applicable)
          </li>
          <li>
            Usage data, which is necessary to maintain, optimize, and enhance
            the Company’s Services
          </li>
          <li>Transaction information</li>
          <li>Information relating to customer support</li>
        </ul>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "18.0pt",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>
            b. For purposes of the legitimate interests of the Company or other
            parties:
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          This includes using personal data to maintain and enhance our Users’
          safety and security. For example, we use personal data to prevent the
          use of our Services by Users who have engaged in inappropriate or
          dangerous behaviour, such as by retaining data of banned Users to
          prevent their use of the Application.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          This also includes purposes such as combating fraud; improving our
          Services, direct marketing, research, and development; and enforcing
          the Company’s User Agreement.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          In addition, it includes using personal data to the extent necessary
          for the interests of other people or the general public, such as in
          connection with legal or insurance claims, and protecting the rights
          and safety of others.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>c. To fulfill the Company’s legal obligations:</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          We collect and use personal data to comply with applicable laws. The
          Company may also share data with law enforcement regarding criminal
          acts or threats to public safety, or requests by third parties
          pursuant to legal processes.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>d. With consent</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          The Company may collect and use personal data based on the User’s
          consent. For example, we may collect personal data through voluntary
          surveys. Responses to such surveys are collected on the basis of
          consent and will be deleted once no longer necessary for the purposes
          collected.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          A User who has provided consent to the collection or use of their
          personal data can revoke it at any time. However, the User will not be
          able to use any Service or feature that requires the collection or use
          of that personal data.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>4. Policy Changes</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          It is our policy to post any changes we make to our Policy on this
          page. If we make material changes to how we treat our users' personal
          information, through a notice on the Website home page or in the
          Application. The date the Policy was last revised is identified at the
          top of the page. You are responsible for ensuring we have an
          up-to-date active and deliverable email address for you, and for
          periodically visiting our Resources and this Policy to check for any
          changes.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>5. Our contact</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          If you have any questions about this Policy or the data we hold from
          you, or you would like to exercise one of your data protection rights,
          please do not hesitate to contact us:
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          Email: <a href="mailto:inquiry@cendmate.com">inquiry@cendmate.com</a>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>6. Authorities</strong>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          Should you wish to report a complaint or if you feel that the Company
          has not addressed your concern in a satisfactory manner, you may
          contact your local Data Protection Authority.
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          Website:{" "}
          <a href="https://www.priv.gc.ca/en/">https://www.priv.gc.ca/en/</a>
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            margin: "0cm",
            fontSize: 16,
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
      </div>
    </div>
  );
}
