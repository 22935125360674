import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { login } from '../../actions/userActions'
import cendMateLogoBlack from '../../assets/images/cendBlack.png';
import './Login.css';

const Login = ({ location, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false);

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo && userInfo.name) {
      history.push(redirect)
    }
    else if(userInfo && userInfo.status === "PENDING"){
      history.push('/verify')
    }
    if(error){
      window.scrollTo(0, 0)
    }
  }, [history, userInfo, redirect, error])

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleKeepSignedInChange = (event) => {
    setKeepSignedIn(event.target.checked);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(login(email, password))
  }
  
  
  

  return (
    <div className="pt-100">
      <Container>
          {error && <Message variant='danger'>{error}</Message>}
      </Container>
    {loading ? <Loader /> :(
        <Container className="d-flex justify-content-center align-items-center h-100">
        <Card className="p-4 shadow">
          <Row className="mb-4">
            <Col className="d-flex justify-content-center align-items-center">
              <div className="rounded-circle border border-primary d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px' }}>
                <img src={cendMateLogoBlack} alt="profile" style={{ width: '70%', height: '65%'}} />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <h4 className="fw-bold mb-0 text-primary">Welcome back</h4>
              <p>Login to your Cendmate account to continue</p>
            </Col>
          </Row>
          <Form >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Keep me signed in" checked={keepSignedIn} onChange={handleKeepSignedInChange} />
            </Form.Group>
  
            <Button variant="primary" type="submit" className="w-100" onClick={submitHandler}>
              Sign in
            </Button>
          </Form>
          <Row className="mt-4">
            <Col className="text-end">
              <a href="/resetpassword">Forgot password?</a>
            </Col>
          </Row>
        </Card>
      </Container>
    )}
    
    </div>
  );
};

export default Login;
