import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Loader from '../Loader';

const DepositModal = ({ show, onHide, onDeposit, loadingPayIn, payInLink, currency, foundWallet, error }) => {
  const [amount, setAmount] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const isDepositDisabled = !confirmed;

  const handleDeposit = () => {
    if (amount && confirmed) {
      onDeposit(parseFloat(amount));
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Deposit Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingPayIn ? (
          <Loader />
        ) : error ? (
          <Alert variant="danger">
            <strong>Error:</strong> {error}
          </Alert>
        ) : payInLink ? (
          <div>
            <div className="py-2">
              <strong>Congratulations!</strong> Your pay-in link has been generated successfully.
              <br />
              Please use the following pay-in link to deposit <strong>{amount} {currency}</strong> in your wallet
              from your <strong>mobile money</strong> account.
              <br />
              <em>Please note: Some charges may apply from the payment provider.</em>
              <br />
            </div>
            <Alert variant="success">
              <div className="d-flex flex-row">
                <a href={payInLink} target="_blank" rel="noopener noreferrer">
                  {payInLink}
                </a>
                <i className="fas fa-external-link-alt mx-2"></i>
              </div>
            </Alert>
          </div>
        ) : currency === 'NGN' ? (
          <div>
            <div>
              <strong>Account Number:</strong> {foundWallet.mapleRadAccount.account_number}
            </div>
            <div>
              <strong>Bank Name:</strong> {foundWallet.mapleRadAccount.bank_name}
            </div>
          </div>
        ) : (
          <Form>
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="confirmCheckbox" className="mt-3 align-items-center">
              <Form.Check
                type="checkbox"
                label="I confirm that I want to proceed with this deposit."
                checked={confirmed}
                onChange={() => setConfirmed(!confirmed)}
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {loadingPayIn || payInLink || error || currency === 'NGN' ? (
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        ) : (
          <>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeposit} disabled={isDepositDisabled}>
              Deposit
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DepositModal;
