import axios from 'axios'
import {
  WALLET_LIST_FAIL,
  WALLET_LIST_REQUEST,
  WALLET_LIST_SUCCESS
} from '../constants/walletConstants'


export const getWalletList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: WALLET_LIST_REQUEST,
    })

    //Store the userToken in a variable
    const userToken = getState().userVerify.userInfo.token;
    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    //call the api to fetch the list of wallets for the loggedIn user
    const {data} = await axios.get('/api/wallets/profile',config)
    dispatch({
      type: WALLET_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    
    dispatch({
      type: WALLET_LIST_FAIL,
      payload: message,
    })
  }
}




