import axios from "axios"

export const registerWallet = async (userInfo, currency, channel) => {
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const body = {
    currency,
    // Add the channel property if currency is "NGN" and channel is selected
    ...(currency === "NGN" && channel ? { channel } : {}),
  };

  try {
    const { data } = await axios.post(`/api/wallets/profile`, body, config);
    console.log(data); // Assuming the response contains the created wallet details
    return {
      success: true,
      walletId: data._id,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
    };
  }
};
