import { DateTime } from "luxon";
function addCommasToNumber(num) {
  if (typeof num !== "number") {
    return "Invalid input. Please enter a number.";
  }

  // Round the number to 2 decimal places
  const roundedNum = Math.round(num * 100) / 100;

  // Convert the rounded number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = roundedNum.toString().split(".");

  // Add commas to the integer part
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted number with 2 decimal places
  if (decimalPart) {
    return `${integerWithCommas}.${decimalPart.padEnd(2, "0")}`;
  } else {
    return `${integerWithCommas}.00`;
  }
}

// function convertTimestampToFullDateTimeWithTimezone(timestamp) {
//   if (typeof timestamp !== 'string') {
//     return 'Invalid input. Please enter a valid timestamp string.';
//   }

//   // Create a Date object from the timestamp
//   const date = new Date(timestamp);

//   // Format the date with full date and time along with timezone
//   const formattedDateTime = date.toLocaleString('en-US', {
//     year: 'numeric',
//     month: 'numeric',
//     day: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric',
//     timeZoneName: 'short',
//   });

//   return formattedDateTime;
// }

function convertTimestampToFullDateTimeWithTimezone(timestamp) {
  if (typeof timestamp !== "string") {
    return "Invalid input. Please enter a valid timestamp string.";
  }

  // Create a DateTime object from the timestamp
  const utcDatetime = DateTime.fromISO(timestamp);

  // Identify the user's timezone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the DateTime to the user's timezone
  const userDatetime = utcDatetime.setZone(userTimezone);

  // Format the date with full date and time along with timezone
  const formattedDateTime = userDatetime.toLocaleString(DateTime.DATETIME_FULL);

  return formattedDateTime;
}

function isNiumCurrency(currency) {
  const supportedCurrencies = ["CAD", "USD", "AUD", "EUR"];
  return supportedCurrencies.includes(currency);
}

function provincesTerritories(countryCode) {
  return [
    { value: "AB", label: "Alberta" },
    { value: "BC", label: "British Columbia" },
    { value: "MB", label: "Manitoba" },
    { value: "NB", label: "New Brunswick" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NS", label: "Nova Scotia" },
    { value: "ON", label: "Ontario" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "QC", label: "Quebec" },
    { value: "SK", label: "Saskatchewan" },
    { value: "NT", label: "Northwest Territories" },
    { value: "NU", label: "Nunavut" },
    { value: "YT", label: "Yukon" },
  ];
}

export {
  addCommasToNumber,
  convertTimestampToFullDateTimeWithTimezone,
  isNiumCurrency,
  provincesTerritories,
};
