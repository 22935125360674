import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { provincesTerritories } from "../../utility/utilityFunctions";
import Select from "react-select";

const BeneficiaryForm = () => {
  return (
    <Form>
      <Form.Group controlId="clientLegalEntity">
        <Form.Label>Client Legal Entity</Form.Label>
        <Form.Control placeholder="Client legal Entity" type="text" />
      </Form.Group>
      <Row className="my-2">
        <Form.Group as={Col} controlId="destinationCountry">
          <Form.Label>Destination country</Form.Label>
          <Form.Control placeholder="Canada" type="text" disabled />
        </Form.Group>
        <Form.Group as={Col} controlId="countryCode">
          <Form.Label>Country code</Form.Label>
          <Form.Control placeholder="CA" type="text" disabled />
        </Form.Group>
      </Row>
      <Form.Group controlId="beneficiaryName">
        <Form.Label>Name</Form.Label>
        <Form.Control placeholder="Name" type="text" />
      </Form.Group>
      <Form.Group controlId="beneficiaryAddress">
        <Form.Label>Address</Form.Label>
        <Form.Control placeholder="Address" type="text" />
      </Form.Group>
      <Row className="my-2">
        <Form.Group as={Col} controlId="beneficiaryCity">
          <Form.Label>City</Form.Label>
          <Form.Control placeholder="City" type="text" />
        </Form.Group>
        <Form.Group as={Col} controlId="beneficiaryState">
          <Form.Label>State</Form.Label>
          <Select options={provincesTerritories()} placeholder="State" />
        </Form.Group>
        <Form.Group as={Col} controlId="beneficiaryPostCode">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control placeholder="Postal Code" type="text" />
        </Form.Group>
      </Row>
      <Form.Group as={Col} controlId="beneficiaryAccountType">
        <Form.Label>Account Type</Form.Label>
        <Select
          options={[
            { value: "Individual", label: "Individual" },
            { value: "Corporate", label: "Corporate" },
          ]}
          placeholder="Select your account type"
        />
      </Form.Group>

      <Row className="my-2">
        <Form.Group as={Col} controlId="beneficiaryAccountNumber">
          <Form.Label>Account Number</Form.Label>
          <Form.Control placeholder="Account Number" type="text" />
        </Form.Group>
        <Form.Group as={Col} controlId="beneficiaryBankCode">
          <Form.Label>Bank Code</Form.Label>
          <Form.Control placeholder="XXX" type="number" maxLength={3} />
        </Form.Group>
        <Form.Group as={Col} controlId="routingCodeValue1">
          <Form.Label>Transit Number</Form.Label>
          <Form.Control placeholder="XXXXX" type="number" maxLength={5} />
        </Form.Group>
      </Row>
    </Form>
  );
};

export default BeneficiaryForm;
