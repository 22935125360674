import React, {useState} from 'react'
import { Alert } from 'react-bootstrap'

const Message = ({ variant, dismiss, children }) => {
  const [show, setShow]=useState(true);
  return (
    dismiss?(<Alert show={show} variant={variant} onClose={()=>setShow(false)} dismissible>{children}</Alert>):(<Alert variant={variant} >{children}</Alert>)
  )
}

Message.defaultProps = {
  variant: 'info',
}

export default Message
