import React, { useState, useEffect } from 'react'
import {Form} from 'react-bootstrap'
import axios from 'axios'
import Loader from '../components/Loader'
import Message from '../components/Message'

const ComplyVerify = ({ location, history }) => {
  const [selfieData, setSelfieData] = useState('')
  const [selfieResult, setSelfieResult] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [doc1Data, setDoc1Data] = useState('')
  const [doc1Result, setDoc1Result] = useState('')
  const [doc2Data, setDoc2Data] = useState('')
  const [typeOfId, setTypeOfId] = useState('passport')

  useEffect(() => {
  }, [history])

  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    const uploadData = {
      clientId:"63b9d2841d74a500081db579",
      documentId:doc1Result.id,
      livePhotoId:selfieResult.id
    }
    //call the api to submit the KYC Request
    const { data } = await axios.post('/api/kyc/check', uploadData).catch(function (error) {
      setError("There is an errror")
      setLoading(false)
      console.log(error.toJSON());
    });
    if(data){
      setSuccess("Great you have been verified successfully, the outcome of the check: "+data.result.outcome)
      setLoading(false)
      console.log(data)
    }
    console.log("Clicked on the submit button")
  }

  //Upload Handlers for Images
  const uploadSelfieHandler = async (e) => {
    setLoading(true)

    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)

    try {
       // size is in bytes
      if (file.size > 2000000) {
        setSelfieData("File too large")
        console.log("File too large");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const uploadData = {
          clientId:"63b9d2841d74a500081db579",
          selfieData:reader.result
        }
        //call the api to uploadSelfie
        const { data } = await axios.post('/api/kyc/livePhoto', uploadData)
        if(data){
          setSelfieResult(data)
          console.log(data)
          setSelfieData(reader.result)
          setLoading(false)
        }
        else{
          console.log("There is an error calling the livePhoto Route")
          setLoading(false)
          setError("There is an error uploading the image")
        }
      };

    } catch (error) {
      console.error(error)
    }
  }

  //Upload Handlers for Images
  const uploadSide1Handler = async (e) => {
    setLoading(true)
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)

    try {
       // size is in bytes
      if (file.size > 2000000) {
        setSelfieData("File too large")
        console.log("File too large");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async() => {
        const uploadData = {
          clientId:"63b9d2841d74a500081db579",
          documentType:typeOfId,
          documentFrontData:reader.result
        }
        //call the api to uploadSelfie
        const { data } = await axios.post('/api/kyc/documentFront', uploadData)
        if(data){
          setDoc1Result(data)
          setDoc1Data(reader.result)
          setLoading(false)
        }
        else{
          console.log("There is an error calling the documentFront Route")
          setLoading(false)
          setError("There is an error uploading the Document")
        }
      };

    } catch (error) {
      console.error(error)
    }
  }

  //Upload Handlers for Images
  const uploadSide2Handler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)

    try {
       // size is in bytes
      if (file.size > 2000000) {
        setSelfieData("File too large")
        console.log("File too large");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        console.log(reader.result) //base64encoded string
        setDoc2Data(reader.result)
      };

    } catch (error) {
      console.error(error)
    }
  }
  

  return (
    <>
    <div className="pt-50">
    <div className="display-4 text-center">KYC</div>
      {loading?(<Loader/>):error?(<Message variant='danger'>{error}</Message>):success?(<Message variant='success'>{success}</Message>):(
        <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 ">
              <Form onSubmit={submitHandler}>
                <div className="h4">Step 1 (Upload Live Photo)</div>
                <Form.Group controlId='image' className=''>
                    <img className="img-fluid my-3 w-50" src={(selfieData.length>0 ?(selfieData):"/images/sample.jpg")} alt="" />
                    <div className="">Upload Live Photo:</div>
                    <Form.File
                      id='image-file'
                      custom
                      onChange={uploadSelfieHandler}
                    ></Form.File>
                </Form.Group>

                {selfieResult && selfieResult.id ? (
                <>
                  <div className="h4">Step 2 (Create and Upload Document)</div>
                  <div className="py-2">
                    <label htmlFor="exampleFormControlSelect1">Choose Document Type</label>
                    <select className="form-control" value={typeOfId} onChange={(e) => {setTypeOfId(e.target.value);}} name='typeOfId' id="exampleFormControlSelect1">
                        <option>passport</option>
                        <option>driving_license</option>
                        <option>national_identity_card</option>
                    </select>
                </div>

                <Form.Group controlId='image' className=''>
                    <img className="img-fluid my-3 w-50" src={(doc1Data.length>0 ?(doc1Data):"/images/sample.jpg")} alt="" />
                    <div className="">Upload Front Side of ID:</div>
                    <Form.File
                      id='image-file'
                      custom
                      onChange={uploadSide1Handler}
                    ></Form.File>
                </Form.Group>
                </>): <><div className="text-muted">Please Complete the Above Step to move further.</div></>}

                {doc1Result && doc1Result.id?(
                  <>
                    <Form.Group controlId='image' className=''>
                        <img className="img-fluid my-3 w-50" src={(doc2Data.length>0 ?(doc2Data):"/images/sample.jpg")} alt="" />
                        <div className="">Upload Back Side of ID (Optional):</div>
                        <Form.File
                          id='image-file'
                          custom
                          onChange={uploadSide2Handler}
                        ></Form.File>
                    </Form.Group>

                    <div className="h4">Step 3 (Submit and Get Verified)</div>
                    <div className="text-center my-3">
                      <button className='btn btn-success' type='submit'>Submit</button>
                    </div>
                  </>
                ):null}
              </Form>
            </div>
          </div>
        </div>
      </>
      )}
    </div>
    </>
  )
}

export default ComplyVerify
