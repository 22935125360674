import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Modal from 'react-bootstrap/Modal';
import { getUserDetails, updateUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'
import { USER_DETAILS_RESET } from '../constants/userConstants'

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id

  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [modalPic, setModalPic] = useState('')
  const [modalHeading,setModalHeading] = useState('')
  const [recoveryEmail, setRecoveryEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [image, setImage] = useState('')
  const [password, setPassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [show, setShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false); 

  

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  useEffect(() => {
    if (successUpdate) {
      
      setSuccessShow(true)
      //Show the message for 3 seconds
      setTimeout(()=>{
        setSuccessShow(false)
        dispatch({ type: USER_UPDATE_RESET })
        dispatch({ type: USER_DETAILS_RESET })
        history.push('/admin/userlist')
      }, 3000);
    } else {
      if (!user || !user.fName || user._id !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setFName(user.fName)
        setLName(user.lName)
        setUserName(user.userName)
        setEmail(user.email)
        setIsSubmitted(user.isSubmitted)
        setIsVerified(user.isVerified)
        setImage(user.image)
        setRecoveryEmail(user.recoveryEmail)
        setPhoneNumber(user.phoneNumber)
        setEmail(user.email)
        setIsAdmin(user.isAdmin)
      }
    }
  }, [dispatch, history, userId, user, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({ _id: user._id, fName, lName, phoneNumber, recoveryEmail, password, isAdmin, isVerified, isSubmitted }))
  }
  const resetHandler = () =>{dispatch(getUserDetails(userId))};
  const handleClose = () => setShow(false);
  const handleShow = (imgSrc, modalHeading) =>{
    setModalHeading(modalHeading);
    setModalPic(imgSrc);
    setShow(true);
  };

  return (
    <div className='pt-50'>
      <Link to='/admin/userlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <div className="container-fluid">
        <div className="row justify-content-center">
        <div className="col-md-7 ">
        {successShow?<Message variant='success'>Successfully Updated the User Details</Message>:(null)}
        <div className='h1 font-poppins'>Edit User</div>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <>
            <Form onSubmit={submitHandler}>

              <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId='name'>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter name'
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId='lastName'>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type='lname'
                        placeholder='Enter Last Name'
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId='name'>
                      <Form.Label>Display Name</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Enter name'
                        value={fName+" "+lName}
                        readOnly
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId='userName'>
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type='name'
                        placeholder='Username'
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                </div>

              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='email'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Set New Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId='recoveryEmail'>
                      <Form.Label>Recovery Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Enter Recovery Email'
                        value={recoveryEmail}
                        onChange={(e) => setRecoveryEmail(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId='phoneNumber'>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Phone Number'
                        maxLength={10}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                </div>
              <Form.Group controlId='isAdmin'>
                  <Form.Check 
                    type="switch"
                    id="admin-switch"
                    label="Is Admin"
                    checked={isAdmin}
                    onClick={(e) => {
                      if(isAdmin)
                      setIsAdmin(false)
                      else
                      setIsAdmin(true)
                    }}
                  />
              </Form.Group>

              {user.isSubmitted?(<>
            <div className="row py-3">
              <div className="col-12">
                <div className="h3 font-poppins">KYC Verification Details</div>
                <div className="row">
                <div className="col-md-12">
                <div className="d-flex flex-column">
                  <p><span className="font-weight-bold">Type Of ID: </span>{user.typeOfId}</p> 
                  <p><span className="font-weight-bold">ID Number: </span>{user.idNumber}</p> 
                  <p><span className="font-weight-bold">Full Name on ID: </span>{user.fullLegalName}</p>
                </div>
                </div>
              </div>
              <div className="row">
              
                <div className="col-md-4 text-center">
                  <img src={user.picIdFront} alt="" className="img-fluid" onClick={()=>handleShow(user.picIdFront, 'Pic ID Front')} />
                  <div className="lead ">Picture ID Front</div>
                </div>
                <div className="col-md-4 text-center">
                  <img src={user.picIdBack} alt="" className="img-fluid" onClick={()=>handleShow(user.picIdBack, 'Pic ID Back') } />
                  <div className="lead ">Picture ID Back</div>
                </div>
                <div className="col-md-4 text-center">
                  <img src={user.picHoldingId} alt="" className="img-fluid" onClick={()=>handleShow(user.picHoldingId, 'Pic with User Holding Id') } />
                  <div className="lead ">Picture Holding ID</div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex flex-column">
                <p className='font-weight-bold'>Do You wish to Verify this user?</p> 
                <Form.Group controlId='isVerify'>
                    <Form.Check 
                      type="switch"
                      id="verify-switch"
                      label="Is Verified"
                      checked={isVerified}
                      onClick={(e) => {
                        if(isVerified)
                        setIsVerified(false)
                        else
                        setIsVerified(true)
                      }}
                    />
                </Form.Group>
                </div>
              </div>
              <div className="row">
              <div className="d-flex flex-column">
              <p className='font-weight-bold'>Request the KYC Documentation Again?</p> 
              <Form.Group controlId='isSubmitted'>
                  <Form.Check 
                    type="switch"
                    id="submitted-switch"
                    label="(Turn Off to Request KYC Again)"
                    checked={isSubmitted}
                    onClick={(e) => {
                      if(isSubmitted)
                      setIsSubmitted(false)
                      else
                      setIsSubmitted(true)
                    }}
                  />
              </Form.Group>
              </div>
              </div>

                
              </div>
            </div>
            </>):(null)}
            <div className="d-flex flex-row my-4">
            <Button className='mx-2' type='button' variant='light' onClick={resetHandler}>
              Reset
            </Button>
            <Button  type='submit' variant='primary'>
              Update
            </Button>
            </div>

              

            </Form>
            
          </>
         
        )}
          </div>
        </div>
      </div>
      {/* Image Modals */}
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <img className='img-fluid' src={modalPic} alt="" />
        </Modal.Body>
      </Modal>
      {/* Success Modal */}
      <Modal size="sm" show={successShow} centered >
        <Modal.Header >
          <Modal.Title className="font-poppins">Update Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <div className="lead">User successfully updated. You will be redirected Soon!</div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default UserEditScreen
