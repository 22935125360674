import {
    EXCHANGE_DETAILS_FAIL,
    EXCHANGE_DETAILS_REQUEST,
    EXCHANGE_DETAILS_RESET,
    EXCHANGE_DETAILS_SUCCESS
  } from '../constants/exchangeConstants'
  
  
  
  export const exchangeDetailsReducer = (state = { exchange: {} }, action) => {
    switch (action.type) {
      case EXCHANGE_DETAILS_REQUEST:
        return { ...state, loading: true }
      case EXCHANGE_DETAILS_SUCCESS:
        return { loading: false, exchange: action.payload }
      case EXCHANGE_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      case EXCHANGE_DETAILS_RESET:
        return { exchange: {} }
      default:
        return state
    }
  }