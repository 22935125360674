import React from 'react';
import Exchanger from '../../components/Exchanger/Exchanger';

const ExchangePage = ({ }) => {
  

  return (
    <>
        <section className='banner-area pt-100'></section>
        <div className="container">
            <div className="h2 pt-3">Convert Currency</div>
            <div className="pt-100 pb-100"> <div className="row position-relative">
                    <div className="col-12">
                    <div className="floated-widget bg-gradient-theme box-shadow-1">
                        <Exchanger/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default ExchangePage;