import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormControl, Button } from 'react-bootstrap';
import { AddressAutofill } from '@mapbox/search-js-react';
import axios from 'axios'
import "react-datepicker/dist/react-datepicker.css";

import Message from '../components/Message';
import Loader from '../components/Loader';

import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'
import AvatarSelector from '../components/AvatarSelector/AvatarSelector';



const UserProfileScreen = ({location, history}) => {
    const [editMode, setEditMode] = useState({
        name: false,
        phoneNumber: false,
        dob: false,
        recoveryEmail: false,
        address:false,
        password:false
      });
      const [user, setUser] = useState({
        image:'',
        fName: '',
        lName: '',
        phoneNumber: '',
        dob: new Date(),
        recoveryEmail:"",
        email:"",
        isSubmitted:null,
        isVerified:null
      });
      const [address, setAddress] = useState({
        addressLine1: '',
        apartment:'',
        city:'',
        state:'',
        country:'',
        countryCode:'',
        postal:''
      })
      const [oldPassword, setOldPassword] = useState("");
      const [newPassword, setNewPassword] = useState("");
      const [confirmPassword, setConfirmPassword] = useState("");
      const [perror, setPError] = useState("");

      const [currentImage, setCurrentImage] = useState('')

      const [loading, setLoading] = useState(false)

      const [message, setMessage] = useState({type:null,text:null});

      const dispatch = useDispatch()

      const userDetails = useSelector((state) => state.userDetails)
      const { loading :loadingDetails, error, user:userDetail } = userDetails
    
      const userVerify = useSelector((state) => state.userVerify)
      const { userInfo } = userVerify
    
      const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
      const { success } = userUpdateProfile

      useEffect(() => {
        if (!userInfo) {
          history.push('/login')
        } else {
            
          if (!userDetail.fName || userInfo._id !==userDetail._id) {
            dispatch({ type: USER_UPDATE_RESET })
            dispatch(getUserDetails('profile'))
            //console.log(userInfo)
            
          } else {
            //map all the variables and replace with the variables in userDetail
            setUser({
                fName:userDetail.fName,
                lName:userDetail.lName,
                phoneNumber:userDetail.phoneNumber,
                dob:userDetail.dob ? new Date(userDetail.dob).toISOString().substring(0, 10):'',
                recoveryEmail:userDetail.recoveryEmail,
                email:userDetail.email,
                isSubmitted:userDetail.isSubmitted,
                isVerified:userDetail.isVerified
            });
            //console.log(userDetail)
            //setAddress
            setAddress(userDetail.address);
            setCurrentImage(userDetail.image);
          }
        }
      }, [dispatch, history, userInfo, userDetail])

      // Call handleSave whenever user.image changes
        useEffect(() => {
            if (user.image) {
            handleSave("image");
            }
        }, [user.image]);

      const handleEdit = field => {
        setEditMode({ ...editMode, [field]: true });
      };
    
      const handleSave = field => {
        setEditMode({ ...editMode, [field]: false });
        try{
            dispatch(updateUserProfile({ ...user })).then((response) => {
                // success logic here
                console.log(response)
                alert('User profile updated successfully!');
              }).catch((error) => {
                // error logic here
                console.log(error)
                alert('There was an error updating the user profile.');
              });
        }
        catch(error){
            setMessage({type:'danger',text:'There was a problem updating user profile'})
        }
      };
    
      const handleClose = field => {
        setEditMode({ ...editMode, [field]: false });
      };

      const sendPasswordUpdate = async (passwordData) =>{
        try {
            const config = {
                headers: {
                Authorization: `Bearer ${userInfo.token}`,
              }
            }
            const response = await axios.put(`/api/users/passwordReset`, passwordData, config);
            return response.data;
          } catch (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              throw new Error(error.response.data.error || "Server failed to process the Password Request.");
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              throw new Error("No response received from server while updating the password.");
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              throw new Error("Cannot reach the server, password cannot be updated.");
            }
          }
      }

      const handlePasswordReset = async () => {
        handleClose("password");
        setLoading(true);
        // Validate new password and confirm password
        if (newPassword !== confirmPassword) {
          setMessage({type:'danger', text:"New password and confirm password do not match"});
          setLoading(false);
          return;
        }
                //call the addressUpdate
                try {
                    const passwordData = {
                        oldPassword,
                        newPassword,
                        userId: userDetail._id
                    }
                    const updatedPassword = await sendPasswordUpdate(passwordData);
                    if(updatedPassword){
                        setMessage({type:'success', text:"Password saved successfully!"});
                        setLoading(false);
                        return
                    }
                    else{
                        setMessage({type:'danger', text:"Password cannot be saved!"});
                        setLoading(false);
                        return;
                    }
                }
                catch(error){
                    console.log(error)
                    setMessage({type:'danger', text:"Error calling the password service"});
                    setLoading(false);
                    return;
                }
                finally{
                    setLoading(false);
                }
      };

      const updateAddress = async () => {
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          }
          const response = await axios.put(`/api/addresses/${address._id}`, address, config);
          return response.data;
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new Error(error.response.data.error || "Server failed to process the Address Request.");
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            throw new Error("No response received from server while updating the address.");
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            throw new Error("Cannot reach the server, address cannot be updated.");
          }
        }
      };

      const handleAddressSave = async () => {
        handleClose('address');
        setLoading(true);
        //call the addressUpdate
        try {
            const updatedAddress = await updateAddress();
            if(updatedAddress){
                setMessage({type:'success', text:"Address saved successfully!"});
                setLoading(false);
                return
            }
            else{
                setMessage({type:'danger', text:"Address cannot be saved!"});
                setLoading(false);
                return;
            }
        }
        catch(error){
            console.log(error)
            setMessage({type:'danger', text:"Error calling the Address service"});
            setLoading(false);
            return;
        }
        finally{
            setLoading(false);
        }
        
      }

      const currentFormattedAddress =()=>{
        return address.addressLine1+", "+address.city+", "+address.state+", "+address.postal;
      }

      //Avatart Selected
    const onAvatarSelected = async(imagePath) =>{
        console.log(imagePath+"is sleected")
        setUser({ ...user, image: imagePath })
    }
    
      return (
        <>
        <section className='banner-area pt-100'>
      </section>
        <div className="pt-50">
            <div className="container">
                <div className="row mb-2 ">
                    <div className="col-md-4 text-center pb-4">
                        <div className="h3 py-2 ">Profile Picture</div>
                        {(loadingDetails || loading) ? (
                            <Loader />
                        ) : error ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <>
                            <div>
                                {(user.image || currentImage.length>0)?(<AvatarSelector onAvatarSelect={onAvatarSelected} userImage={user.image?(user.image):(currentImage)}/>):(<Loader/>)}
                            </div>
                            </> ) }
                            
                    </div>
                    {/* Profile Information Col */}
                    <div className="col-md-8 border border-secondary p-4">
                    <div className="h3 py-4">Profile Information</div>
                    {(message && message.text) && <div className="pt-3"><Message  variant={message.type}>{message.text}</Message></div>}
                    {(loadingDetails || loading) ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>{error}</Message>
                    ) : (
                    <Form>
                        {/* Name Group */}
                        <Form.Group className="py-2"> 
                            <div className="h4">Name</div>
                                {editMode.name ? (
                                <div className='animate__animated animate__fadeIn'>
                                    <Form.Label>First Name:</Form.Label>
                                    <FormControl
                                    type="text"
                                    value={user.fName}
                                    readOnly
                                    />
                                    <Form.Label>Last Name:</Form.Label>
                                    <FormControl
                                    type="text"
                                    value={user.lName}
                                    readOnly
                                    />
                                    <div className="d-flex flex-row justify-content-end py-2">
                                    <Button variant='light' className='mx-2'  onClick={() => handleClose("name")}>Cancel</Button>
                                    </div>
                                </div>
                                ) : (
                                <>
                                    
                                    <div className="d-flex flex-row">
                                        <Form.Control plaintext readOnly value={user.fName+' '+user.lName} />
                                        <Button variant="outline-primary" onClick={() => handleEdit("name")}>View</Button>
                                    </div>
                                </>
                                )}
                        </Form.Group>
                        {/* Address Info */}
                        <Form.Group className="py-2">
                            <div className="border-top pb-3"></div>
                            <div className="h4">Address</div>
                                {editMode.address ? (
                                <div className='animate__animated animate__fadeIn'>
                                    <AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_TOKEN}>
                                        <Form.Label>Address Line1:</Form.Label>
                                        <FormControl name="address" placeholder="Address" type="text"
                                            autoComplete="address-line1"
                                            value={address.addressLine1}
                                            onChange={(e) => setAddress({ ...address, addressLine1: e.target.value })}/>
                                    </AddressAutofill>

                                    <Form.Label>Apartment, suite, unit, etc (optional):</Form.Label>
                                    <FormControl name="apartment" placeholder="Apartment number" type="text"
                                        autoComplete="address-line2"
                                        value={address.apartment ?address.apartment  :''}
                                        onChange={(e) => setAddress({ ...address, apartment: e.target.value })} />
                                    
                                    <Form.Label>City:</Form.Label>
                                    <FormControl  name="city" placeholder="City" type="text"
                                        autoComplete="address-level2"
                                        value={address.city}
                                        onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                                    
                                    <Form.Label>State/Province:</Form.Label>
                                    <FormControl  name="state" placeholder="State" type="text"
                                        autoComplete="address-level1"
                                        value={address.state}
                                        onChange={(e) => setAddress({ ...address, state: e.target.value })} />
                                    
                                    <Form.Label>Country:</Form.Label>
                                    <FormControl  name="country" placeholder="Country" type="text"
                                        autoComplete="country-name"
                                        value={address.country}
                                        onChange={(e) => setAddress({ ...address, country: e.target.value })} />

                                    <Form.Label>Country Code (Two Letter ISO):</Form.Label>
                                    <FormControl  name="country" placeholder="Country" type="text"
                                        autoComplete="country"
                                        value={address.countryCode}
                                        onChange={(e) => setAddress({ ...address, countryCode: e.target.value })} />
                                    
                                    <Form.Label>Postal Code:</Form.Label>
                                    <FormControl  name="postcode" placeholder="Postcode" type="text"
                                        autoComplete="postal-code"
                                        value={address.postal}
                                        onChange={(e) => setAddress({ ...address, postal: e.target.value })} />

                                    <div className='d-flex flex-row justify-content-end py-2'>
                                        <Button variant='light' className='mx-2' onClick={() => handleClose("address")}>Close</Button>
                                        <Button onClick={() => handleAddressSave()}>Save</Button>
                                    </div>
                                
                                </div>
                                ) : (
                                <>
                                    
                                    <div className="d-flex flex-row">
                                        <Form.Control plaintext readOnly value={address.addressLine1 && address.addressLine1.length>0 ?currentFormattedAddress():'Please Enter Your Address'} />
                                        <Button variant="outline-primary" onClick={() => handleEdit("address")}>Edit</Button>
                                    </div>
                                </>
                                )}
                        </Form.Group>
                        {/* Phone Group */}
                        <Form.Group className="py-2">
                            <div className="border-top pb-3"></div>
                            <div className="h4">Phone number</div>
                            {editMode.phoneNumber ? (
                            <div className='animate__animated animate__fadeIn'>
                                <Form.Label>Phone number:</Form.Label>
                                <FormControl
                                type="text"
                                value={user.phoneNumber}
                                onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
                                />
                                <div className="d-flex flex-row justify-content-end py-2">
                                    <Button variant='light' className='mx-2' onClick={() => handleClose("phoneNumber")}>Close</Button>
                                    <Button  onClick={() => handleSave("phoneNumber")}>Save</Button>       
                                </div>
                                
                            </div>
                            ) : (
                            <>
                                <div className="d-flex flex-row">
                                    <Form.Control plaintext readOnly value={user.phoneNumber} />
                                    <Button variant="outline-primary" onClick={() => handleEdit("phoneNumber")}>Edit</Button>
                                </div>
                            </>
                            )}
                        </Form.Group>
                        {/* Recovery Email Group */}
                        <Form.Group className="py-2">
                            <div className="border-top pb-3"></div>
                            <div className="h4">Recovery Email</div>
                                {editMode.recoveryEmail ? (
                                <div className='animate__animated animate__fadeIn'>
                                    <Form.Label>Recovery Email:</Form.Label>
                                    <FormControl
                                    type="email"
                                    value={user.recoveryEmail}
                                    onChange={(e) => setUser({ ...user, recoveryEmail: e.target.value })}
                                    />
                                    <div className="d-flex flex-row justify-content-end py-2">
                                    <Button variant='light' className='mx-2'  onClick={() => handleClose("recoveryEmail")}>Cancel</Button>
                                    <Button   onClick={() => handleSave("recoveryEmail")}>Save</Button>
                                    </div>
                                </div>
                                ) : (
                                <>
                                    
                                    <div className="d-flex flex-row">
                                        <Form.Control plaintext readOnly value={user.recoveryEmail} />
                                        <Button variant="outline-primary" onClick={() => handleEdit("recoveryEmail")}>Edit</Button>
                                    </div>
                                </>
                                )}
                        </Form.Group>

                        {/* Password Group */}
                        <Form.Group className="py-2">
                            <div className="border-top pb-3"></div>
                            <div className="h4">Password</div>
                            {perror && <Message className='py-2' variant='danger'>{perror}</Message>}
                            {editMode.password ? (
                            <div className='animate__animated animate__fadeIn'>
                                <Form.Label>Old Password:</Form.Label>
                                <FormControl
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                />
                                <Form.Label>New Password:</Form.Label>
                                <FormControl
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <Form.Label>Confirm New Password:</Form.Label>
                                <FormControl
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <div className='d-flex flex-row justify-content-end py-2'>
                                    <Button variant='light' className='mx-2' onClick={() => handleClose('password')}>Close</Button>
                                    <Button onClick={() => handlePasswordReset()}>Save</Button>
                                </div>
                            </div>
                            ) : (
                            <>
                                <div className="d-flex flex-row">
                                    <Form.Control plaintext readOnly value={'••••••'} />
                                    <Button variant="outline-primary" onClick={() => handleEdit("password")}>Edit</Button>
                                </div>
                            </>
                            )}
                        </Form.Group>

                        {/* Date of Birth Group */}
                        <Form.Group className="py-2">
                            <div className="border-top pb-3"></div>
                            <div className="h4">Date of Birth</div>
                            {editMode.dob ? (
                            <div className='animate__animated animate__fadeIn'>
                                <Form.Label>Date of Birth (mm/dd/yyyy):</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={user.dob ? new Date(user.dob).toISOString().substring(0, 10) : ''}
                                    min="1950-01-01" max="2010-12-31"
                                    onChange={(e) => setUser({ ...user, dob: e.target.value })}
                                    required
                                    />
                                <div className='d-flex flex-row justify-content-end py-2'>
                                    <Button variant='light' className='mx-2' onClick={() => handleClose("dob")}>Close</Button>
                                    <Button onClick={() => handleSave("dob")}>Save</Button>
                                </div>
                            </div>
                            ) : (
                            <>
                                <div className="d-flex flex-row justify-content-between">
                                    <div >
                                        <Form.Control plaintext readOnly value={user.dob ? new Date(user.dob).toISOString().substring(0, 10) : ''} />
                                        <p>(YYYY-MM-DD)</p>
                                    </div>
                                    <Button variant="outline-primary h-50" onClick={() => handleEdit("dob")}>Edit</Button>
                                </div>
                            </>
                            )}
                        </Form.Group>

                        {/* KYC Group */}
                        <Form.Group className="py-2">
                            <>
                                <div className="border-top pb-3"></div>
                                <div className="h4">KYC Verification</div>
                                {!user.isSubmitted?(
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="">Please get your account verified by our simple KYC process in minutes.</div>
                                    <Button variant="outline-primary h-50" onClick={()=>{history.push('/kyc')}}>Verify</Button>
                                </div>
                                ):(
                                user.isVerified?(
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="">Your account has been verified, thanks and enjoy using Cendmate!</div>
                                    <Button variant="outline-primary h-50" onClick={()=>{history.push('/dashboard')}}> <i className="fas fa-link px-2"></i>Dashboard</Button>
                                </div>
                                ):(
                                <div className="d-flex flex-row justify-content-between">
                                    
                                    <div className="">Your KYC request has been submitted and is pending verification. We'll send you an email at <span className='font-weight-bold'>{user.email}</span> once your account has been verified.</div>
                                </div>
                                ))}
                            </>
                        </Form.Group>
                    </Form> ) }
                    </div>
                </div>
            </div>
        </div>
        </>
  );
};

export default UserProfileScreen;