import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import Message from "../components/Message";
import { registerWallet } from "../services/walletService";
import Loader from '../components/Loader'; // Import the Loader component

const options = [
  { value: "CAD", label: "CAD" },
  { value: "GHS", label: "GHS" },
  { value: "KES", label: "KES" },
  { value: "INR", label: "INR" },
  { value: "NGN", label: "NGN" }, // Add NGN currency option
];

const banksForNGN = [
  { value: "1051", label: "9 Payment Service Bank" },
];

const OpenWallet = ({ history }) => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState('');
  const [selectedBank, setSelectedBank] = useState(null); // To store the selected bank
  const [registerError, setRegisterError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner

  const userVerify = useSelector((state) => state.userVerify);
  const { userInfo } = userVerify;

  const handleCreateWallet = async () => {
    if (currency.value === "NGN" && !selectedBank) {
      // Show an error message if NGN is selected without a bank
      setRegisterError("Please select a bank for NGN currency.");
      return;
    }

    // Show loading spinner while the request is in progress
    setIsLoading(true);

    // Pass the selected bank as a property when calling registerWallet
    const registered = await registerWallet(userInfo, currency.value, selectedBank ? selectedBank.value : null);

    // Hide loading spinner after the request is completed
    setIsLoading(false);

    if (registered && registered.success === true) {
      history.push('/dashboard', { successMessage: 'Wallet added successfully' });
    } else {
      console.log("Failed and registered:")
      console.log(registered)
      if (registered && registered.error) {
        setRegisterError(JSON.stringify(registered.error.response.data.error))
      } else {
        setRegisterError('Sorry something went wrong, please try again or contact support!')
      }
    }
  }

  useEffect(() => {
    if (userInfo) {
      // Do something
      // Fetch the list of all existing wallets for the user
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <>
      <section className='banner-area pt-100'>
      </section>

      <div className="container d-flex flex-column justify-content-center min-vh-100">
        <div className="row justify-content-center mt-minus-100">
        {isLoading ?<Loader title="Making Wallet Request..."/>:
          <div className="col-md-6 text-center">
              {registerError && <Message variant='danger'>{registerError}</Message>}
              <h2 className="mb-4">Open a Wallet</h2>
              <Select options={options} value={currency} className="w-100 mb-4" placeholder="Choose currency" isSearchable={true} onChange={(selectedOption) => { setCurrency(selectedOption); setSelectedBank(null); }} />
              {currency.value === "NGN" && (
                <Select options={banksForNGN} value={selectedBank} className="w-100 mb-4" placeholder="Choose bank" isSearchable={true} onChange={(selectedOption) => setSelectedBank(selectedOption)} />
              )}
              <p className="mb-4">You can open wallets from the above list of currencies.</p>
              <div className="btn btn-primary btn-block rounded-pill px-5" onClick={handleCreateWallet}>
                Create Wallet
              </div>
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default OpenWallet;
