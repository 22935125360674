import React, { useEffect } from 'react';

const JotForm = () => {
  useEffect(() => {
    const container = document.getElementById('jotform-container');

    if (container) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://form.jotform.com/jsform/241930591820253';
      script.async = true;
      script.defer = true;
      container.appendChild(script);

      return () => {
        container.removeChild(script); // Clean up the script when the component unmounts
      };
    }
  }, []);

  return (
    <div>
      <div id="jotform-container"></div>
    </div>
  );
};

export default JotForm;
