import React from 'react';
import { Badge, Image} from 'react-bootstrap';
import './wallet.css';

const Wallet = ({ wallet, addCommasToNumber }) => {
  

  return (
    (wallet && wallet.currency && wallet.amount>=0)?(
        <div className="wallet-box">
        <div className="flag-icon">
          <Image
            src={`/images/flags/${wallet.currency}.png`}
            alt={`${wallet.currency} flag`}
            width={50}
            height={50}
          />
        </div>
        <div className="balance-info">
          <div className="balance">
           {addCommasToNumber(wallet.amount)}
          </div>
          <div className="currency">
              <Badge pill variant="light">
                  <span style={{ fontSize: '12px' }}>
                      {wallet.currency}
                  </span>
              </Badge>
          </div>
        </div>
      </div>
    ):(
    <div className="wallet-box">
        <div className="flag-icon">
          <Image
            src={`/images/plus.png`}
            alt={`Open Account`}
            width={50}
            height={50}
          />
        </div>
        <div className="balance-info">
          <div className="balance">
            Open
          </div>
          <div className="currency">
              <Badge pill variant="light">
                  <span style={{ fontSize: '12px' }}>
                      Multiple currencies
                  </span>
              </Badge>
          </div>
        </div>
      </div>
    )
  );
};

export default Wallet;
