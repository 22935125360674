import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { verify } from '../actions/userActions'

const VerifyScreen = ({ location, history }) => {
  const [otp, setOtp] = useState('')
  const [stats, setStats] = useState({stats:null,variant:'success'})

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const userInfo1 = userLogin.userInfo

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo } = userVerify

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
   if(userInfo1 && userInfo1.status === "ERROR"){
      history.push('/login')
    }
    if(userInfo && userInfo.status === "SUCCESS"){
      history.push('/dashboard')
    }
    if(!userInfo1){
      history.push('/login')
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(verify(userInfo1.userId, otp))
  }

  const resendHandler = async (e) => {
    e.preventDefault()
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try{
      const { data } = await axios.post(
        '/api/users/resend',
        { userId: userInfo1.userId },
        config
      )
      if(data.status === 'PENDING'){
        setStats({stats:"OTP resent, please check you Inbox and Spam folder!", variant:"success"});
      }
      else{
        setStats({stats:"OTP resend fail, please try after some time or contact support", variant:"danger"});
      }
    }

    catch(error){
      setStats({stats:"OTP resend fail, please try after some time or contact support", variant:"danger"});
    }
    // dispatch(verify(userInfo1.userId, otp))
  }

  return (
    <div>
      <section className='banner-area pt-100'>
      </section>
      <FormContainer>
        <h1>Please Verify that it's You!</h1>
        {error && <Message variant='danger' >{error}</Message>}
        {stats.stats && <Message variant={stats.variant} >{stats.stats}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='password'>
            <Form.Label>A secure One-Time Password has been sent to your email address, please enter it below:</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter OTP'
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button type='submit' variant='primary' className='py-2'>
            Submit
          </Button>
        </Form>

        <Row className='py-3'>
          <Col>
            Didn't Recieve an OTP?{' '}
            <Link onClick={resendHandler}>
              Resend OTP
            </Link>
          </Col>
        </Row>
      </FormContainer>
    </div>
  )
}

export default VerifyScreen
