import axios from 'axios';

const BASE_URL = '/api/NGN'; // Change this if your API route is different

export const listBanks = async (userToken) => {
  try {
    const response = await axios.get(`${BASE_URL}/listBanks`, {
      headers: {
        'Authorization': `Bearer ${userToken}`,
      },
    });
    const providers = response.data.banks.map((provider) => ({
        value: provider.code,
        label: provider.name,
      }));
    return providers;
  } catch (error) {
    console.error('Error fetching list of banks:', error);
    throw error;
  }
};

export const createTransfer = async (userToken, accountNumber, bankCode, amount, wallet, reason) => {
  console.log("calling the createTransfer")
  try {
    const response = await axios.post(`${BASE_URL}/transfer`, {
      accountNumber,
      bankCode,
      amount,
      wallet,
      reason,
    }, {
      headers: {
        'Authorization': `Bearer ${userToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating transfer:', error);
    throw error;
  }
};

export const resolveAccount = async (userToken, accountNumber, bankCode) => {
    try {
      const response = await axios.post(`${BASE_URL}/resolveAccount`, {
        accountNumber,
        bankCode,
      }, {
        headers: {
          'Authorization': `Bearer ${userToken}`,
        },
      });
      return { accountName: response.data.accountName, error: null };
    } catch (error) {
      console.error('Error resolving account info:', error);
      return { accountName: null, error: 'Error resolving account info: ' + error.message };
    }
  };
  
