import React from 'react';
import './WalletTable.css';

const WalletTable = ({ wallets, addCommasToNumber}) => {
      
  return (
    <table className="wallet-table">
      <thead>
        <tr>
          <th>Wallet</th>
          <th>Withdrawal Limit</th>
          <th>Deposit Limit</th>
        </tr>
      </thead>
      <tbody>
        {wallets.map((wallet) => (
          <tr key={wallet.id}>
            <td>{wallet.currency}</td>
            <td>{addCommasToNumber(wallet.withdrawalLimit)}</td>
            <td>{addCommasToNumber(wallet.depositLimit)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WalletTable;