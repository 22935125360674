import React, { useState, useEffect } from 'react';
import Loader from '../components/Loader'
// import axios from 'axios';
// import config from '../../config'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import axios from 'axios';


let complycube = null;

function ComplyApp () {
    const history = useHistory()
    // To save the ComplyCube instance
    const [complyCube, setComplyCube] = useState(null);
    const [loading, setLoading] = useState(false)
    //dispatcher to get userVerified details
    const userVerify = useSelector((state) => state.userVerify)
    const { userInfo } = userVerify
    // These are handlers of the ComplyCube events, must be implemented
    const handlers = {
      // Called when the ComplyCube ends the capture
      onComplete: async (data) => {
      // Insert code here
      localStorage.setItem("is_verified", true);

      // send data to server , append the cleintID to the request
      let postData = {...data, clientId:userInfo.clientId, userId:userInfo._id}
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/kyc/document`, postData, config);
      //on completion send to checkStatus page to verify the status of the check, use relocation to refresh state
      // history.push("/profile");
      window.location.replace('/profile');
      complycube.unmount();
    },
    
    // Called when the capture modal is closed
    onModalClose: () => {
      history.push("/profile");
      complycube.unmount();
    },
    
    onError: ({ type, message }) => {
      if (type === 'token_expired'){
        // Request a new SDK token
      } else {
        // Handle other errors
        console.err(message)
      }
    }
  }

  // Initialize SDK
  const mountComplyCube = (token) => {
    return window.ComplyCube.mount({
      token: token,
      containerId: "cc-component",
      stages: [
        'intro',
        {
          name: 'documentCapture',
          options: {
            mode: 'photo',
          }
        },
        'faceCapture',
        'completion'
      ],
      onComplete: handlers.onComplete,
      onModalClose: handlers.onModalClose,
      onError: handlers.onError,
    });
  }

  // creates and get a client's token from the backend
  // https://docs.complycube.com/documentation/guides/web-sdk-quick-guide/integration-guide
  
    // once page loaded set the complyCube instance
    useEffect( () => {
        //check if userInfo exists
        if(!userInfo){
          history.push('/login')
        }
        else{
          async function fetchData(){
            try{
              setLoading(true)
              const config = {
                headers: {
                  Authorization: `Bearer ${userInfo.token}`,
                },
              }
              const clientToken = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/kyc/clientToken/${userInfo.clientId}`,config);
              
              if(userInfo.token && clientToken.data){
                setLoading(false)
                complycube = mountComplyCube(clientToken.data.token);
                setComplyCube(complycube);
              }else{
                history.push("/login");
              }
            }
            catch(error){
              console.error("Error occured in making a call to get clientID from the server")
              console.error(error)
            }
            finally{
              setLoading(false)
            }
          }
          fetchData();
        }
    }, []);
    return (
    <>
    <div className="pt-100">
      <div className="container ">
      {loading?(<Loader/>):(
        <div className="cc-container z-3">
          {/** ComplyCube's container will be rendered inside this div 
           * containerID option must mtach the ID below
          */}
          <div id="cc-component" className='z-3'></div>
      </div>
      )}
      </div>
    </div>
    </>
    ); 
    
}

export default ComplyApp;