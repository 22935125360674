import React, { useState, useEffect } from "react";
import Message from '../components/Message'
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import Loader from '../components/Loader';
import { AddressAutofill } from '@mapbox/search-js-react';
import axios from "axios";

import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input'


import 'react-phone-number-input/style.css';


const UserRegistration = ({location, history}) => {
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [postal, setPostal] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const redirect = location.search ? location.search.split('=')[1] : '/'
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  useEffect(()=>{
    if(message){
      window.scrollTo(0, 0)
    }
  },[message])
  const handleNext = () => {
    if (step === 1) {
      if (
        !fName ||
        !lName ||
        !phoneNumber ||
        !dob ||
        !email ||
        !recoveryEmail
      ) {
        setMessage({type:"danger",text:"All fields in step 1 are required!"});
        return;
      }
      if (!/\S+@\S+\.\S+/.test(email) || !/\S+@\S+\.\S+/.test(recoveryEmail)) {
        setMessage({type:"danger",text:"Please enter a valid email address"});
        return;
      }

      if(email.localeCompare(recoveryEmail, undefined, { sensitivity: 'accent' }) === 0){
        setMessage({type:"danger",text:`Email and RecoveryEmail cannot be same!`});
        return;
      }
     
      if(!isValidPhoneNumber(phoneNumber)){
        setMessage({type:"danger",text:`Please enter a valid phone number : ${phoneNumber} is not valid.`});
        return;
      }
    }
    if (step === 2) {
      if (!addressLine1 || !city || !state || !country || !postal) {
        setMessage({type:"danger",text:"All fields in step 2 are required!"});
        return;
      }
    }
    if (step === 3) {
      if (!password || !confirmPassword) {
        setMessage({type:"danger",text:"Password and Confirm Password fields in step 3 are required!"});
        return;
      }
      if (password !== confirmPassword) {
        setMessage({type:"danger",text:"Password and Confirm Password do not match!"});
        return;
      }
    }
    setMessage(null);
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const addAddress = async (addressData) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post('/api/addresses', addressData, config);
      return response.data.address;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        throw new Error(error.response.data.error || "Server failed to process the Address Request.");
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        throw new Error("No response received from server while creating the address.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        throw new Error("Cannot reach the server, address cannot be posted.");
      }
    }
  };

  //function to add user to the DB
  const addUser = async (userData) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post('/api/users', userData, config);
      console.log(response.data)
      return response.data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        throw new Error(error.response.data.error || "Server failed to process the User Create Request.");
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        throw new Error("No response received from server while creating the user.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        throw new Error("Cannot reach the server, address cannot be posted.");
      }
    }
  };

  const handlePhoneNumberChange = (value) => {
    // if (value) {
    //   setPhoneNumber(value.slice(0, 10)); // Limit to 10 digits
    // } else {
    //   setPhoneNumber('');
    // }
    setPhoneNumber(value)
  };
  

  const handleAgreeCheckbox = () => {
    setAgreedToTerms(!agreedToTerms);
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!password || !confirmPassword) {
      setMessage({type:"danger",text:"Password and Confirm Password fields in step 3 are required!"});
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setMessage({type:"danger",text:"Password and Confirm Password do not match!"});
      setLoading(false);
      return;
    }
    if(!agreedToTerms){
      setMessage({type:"danger",text:"You must agree to the Terms and Conditions of Cendmate."});
      setLoading(false);
      return;
    }

    //else continue with sending the requset
    
    try {
      const addressData = {
        addressLine1,
        apartment,
        city,
        state,
        country,
        countryCode,
        postal
      }

      const address = await addAddress(addressData);
      console.log(address);
      // Do something with the address data
      const userData = {
        fName,
        lName,
        phoneNumber,
        dob,
        email,
        recoveryEmail,
        password,
        address:address._id
      };
      const user = await addUser(userData);
      setDisableSubmit(true);
      setMessage({type:"success",text:"User Registered Successfully, you will be redirected to the Sign In Page now..."})
      // Wait for 3 seconds before redirecting
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (error) {
      console.log(error);
      setMessage({type:"danger",text:error.message||"something went wrong"});
      setLoading(false)
      return;
    }
    finally{
      setLoading(false)
    }
  };

  return (
    <div className="pt-50">
      {loading ? <Loader/>:(
        <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 ">
            <div className="h2 text-center py-3">Sign Up</div>
            <ProgressBar  now={(step / 3) * 100} label={`Step ${step} of 3`} />
            {(message && message.text) && <div className="pt-3"><Message  variant={message.type}>{message.text}</Message></div>}
            <Form className="py-3">
              {step === 1 && (
                <>
                  <div className="h4">Personal Information:</div>
                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={fName}
                      onChange={(e) => setFName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={lName}
                      onChange={(e) => setLName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    {/* <Form.Control
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    /> */}
                    <div className="phone-number-input-container">
                      <PhoneInput
                        international
                        defaultCountry="CA"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter phone number"
                        limitMaxLength
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicDOB">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      value={dob}
                      onChange={(e) => setDOB(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicRecoveryEmail">
                    <Form.Label>Recovery Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={recoveryEmail}
                      onChange={(e) => setRecoveryEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="h4">Address Information:</div>
                  <AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_TOKEN}>
                    <Form.Group controlId="formBasicAddressLine1">
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="address-line1"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </AddressAutofill>
                  <Form.Group controlId="formBasicApartment">
                    <Form.Label>Apartment</Form.Label>
                    <Form.Control
                      autoComplete="address-line2"
                      type="text"
                      value={apartment}
                      onChange={(e) => setApartment(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      autoComplete="address-level2"
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicState">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      autoComplete="address-level1"
                      type="text"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCountry">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      autoComplete="country-name"
                      type="text"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCountry">
                    <Form.Label>Country Code</Form.Label>
                    <Form.Control
                      autoComplete="country"
                      type="text"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPostalCode">
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      autoComplete="postal-code"
                      type="text"
                      value={postal}
                      onChange={(e) => setPostal(e.target.value)}
                      required
                    />
                  </Form.Group>
                </>
              )}
              {step === 3 && (
                <>
                  <div className="h4">Security Information:</div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                  <Form.Check
                        className="my-2"
                        type="checkbox"
                        id="terms-checkbox"
                        label={
                          <span>
                            I agree to the{' '}
                            <a href="/docs/terms.pdf" target="_blank">
                              terms and conditions 
                            </a>
                            of CendMate.
                          </span>
                        }
                        onChange={handleAgreeCheckbox}
                        checked={agreedToTerms}
                    />
                  </Form.Group>
                </>
              )}
              <div className="d-flex justify-content-end py-4">
                {step > 1 && (
                  <Button variant="secondary" className="mx-2" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
                {step < 3 ? (
                  <Button variant="primary" onClick={handleNext}>
                    Next
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleSubmit} disabled={disableSubmit} >
                    Submit
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default UserRegistration;
