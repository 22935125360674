import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  ProgressBar,
  Alert,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import Loader from "../Loader";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Select from "react-select";
import getProviderList from "../../services/providerService"; // Import the providerService function
import { initiateWithdraw } from "../../services/paymentService";
import {
  isNiumCurrency,
  provincesTerritories,
} from "../../utility/utilityFunctions";
import BeneficiaryForm from "../BeneficiaryForm/BeneficiaryForm";

const WithdrawModal = ({
  show,
  onHide,
  onWithdraw,
  loadingWithdraw,
  error,
  currency,
  userToken,
  wallet,
}) => {
  const [phase, setPhase] = useState(1);
  const [amount, setAmount] = useState("");
  const [recipient, setRecipient] = useState({ phoneNumber: "", provider: "" });
  const [confirmed, setConfirmed] = useState(false);
  const [providerOptions, setProviderOptions] = useState([]);
  const [providerLoading, setProviderLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const [withdrawResult, setWithdrawResult] = useState("");
  const [withdrawError, setWithdrawError] = useState(null);
  const [amountError, setAmountError] = useState("");
  const [showBeneficiaryForm, setShowBeneficiaryForm] = useState(false);

  const isWithdrawDisabled = !confirmed || withdrawLoading;

  useEffect(() => {
    if (show) {
      // Reset the state when the modal is shown
      setPhase(1);
      setAmount("");
      setRecipient({ phoneNumber: "", provider: "" });
      setConfirmed(false);
      setProviderOptions([]);
      setProviderLoading(false);
      setWithdrawLoading(false);
      setWithdrawSuccess(false);
      setWithdrawError(null);
    }
  }, [show]);

  const handleNext = () => {
    if (phase === 1 && amount) {
      setPhase(2);
      // Fetch provider options when moving to phase 2
      if (currency === "GHS" || currency === "KES") {
        setProviderLoading(true);
        getProviderList(currency)
          .then((options) => {
            setProviderOptions(options);
            setProviderLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching provider list:", error);
            setProviderLoading(false);
          });
      }
    } else if (phase === 2 && recipient.phoneNumber && recipient.provider) {
      setPhase(3);
    }
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // If the input is just a dot or empty, set the value without formatting
      if (inputValue === "" || inputValue === ".") {
        setAmount(inputValue);
        setAmountError("");
        return;
      }

      // Parse and format to two decimal places if it's a valid number
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        setAmount(numericValue.toFixed(2));
        setAmountError("");
      } else {
        setAmount(inputValue);
      }
    } else {
      // If invalid, set an error
      setAmountError(
        "Invalid input: only numbers and a single decimal point are allowed."
      );
    }
  };

  const handleWithdraw = async () => {
    if (confirmed) {
      setWithdrawLoading(true); // Set loading state when initiating withdrawal
      try {
        // Call initiateWithdraw with the required parameters
        const result = await initiateWithdraw(
          userToken,
          wallet,
          parseFloat(amount),
          recipient
        );
        setWithdrawSuccess(true);
        setWithdrawResult(result);

        // Close the modal or perform other actions
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.log(error.response.data);
        setWithdrawError(error.response.data.error || "Withdrawal failed");
      } finally {
        setWithdrawLoading(false); // Clear loading state
      }
    }
  };

  if (isNiumCurrency(currency)) {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Funds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            now={(phase / 3) * 100}
            label={`Phase ${phase} of 3`}
            variant="info"
            className="mb-3"
          />
          {loadingWithdraw ? (
            <Loader />
          ) : error ? (
            <Alert variant="danger">
              <strong>Error:</strong> {error}
            </Alert>
          ) : phase === 1 ? (
            <Form>
              <Form.Group controlId="amount">
                <Form.Label>Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>CAD$</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="0.00"
                    value={amount}
                    onChange={handleChange}
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {amountError}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          ) : phase === 2 ? (
            <>
              {showBeneficiaryForm ? (
                <BeneficiaryForm />
              ) : (
                <Form>
                  <Form.Group controlId="beneficiaryList">
                    <Form.Label>Select a beneficiary</Form.Label>
                    <Select options={[]} placeholder="Choose a beneficiary" />
                  </Form.Group>
                  <Row className="justify-content-end mt-2">
                    <Col xs={"auto"}>
                      <Button
                        variant="link"
                        onClick={() => setShowBeneficiaryForm(true)}
                      >
                        Add a beneficiary
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </>
          ) : withdrawLoading ? (
            <Loader />
          ) : withdrawSuccess ? (
            <>
              <div className="lead">
                <Alert variant="success">
                  <strong>Success:</strong> Please keep this transaction
                  reference id for future: {withdrawResult.referenceId}
                </Alert>
              </div>
            </>
          ) : withdrawError ? (
            <Alert variant="danger">
              <strong>Error:</strong>
              {withdrawError.length > 0
                ? withdrawError
                : "Fialed to generate withdrawal link"}
            </Alert>
          ) : (
            <div>
              <p>
                <strong>Withdraw Amount:</strong> {amount} {currency}
              </p>
              <p>
                <strong>Recipient's Phone Number:</strong>{" "}
                {recipient.phoneNumber}
              </p>
              <p>
                <strong>Provider:</strong> {recipient.provider}
              </p>
              <Form.Group
                controlId="confirmCheckbox"
                className="mt-3 align-items-center"
              >
                <Form.Check
                  type="checkbox"
                  label="I confirm that I want to proceed with this withdrawal."
                  checked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                />
              </Form.Group>
            </div>
          )}
          {showBeneficiaryForm && (
            <Button
              variant="outline-secondary"
              className="mx-2 mt-3"
              onClick={() => setShowBeneficiaryForm(false)}
            >
              Cancel
            </Button>
          )}
          {phase !== 3 && (
            <Button variant="primary" onClick={handleNext} className="mt-3">
              Next
            </Button>
          )}
        </Modal.Body>
        <Modal.Footer>
          {loadingWithdraw || phase === 1 || error ? (
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
          ) : phase === 2 ? (
            <Button variant="secondary" onClick={() => setPhase(1)}>
              Back
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }

  // Check if the currency is not GHS or KES
  if (currency !== "GHS" && currency !== "KES") {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Unsupported Currency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Withdrawals are only supported for GHS (Ghanaian Cedi) and KES
            (Kenyan Shilling).
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Withdraw Funds</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar
          now={(phase / 3) * 100}
          label={`Phase ${phase} of 3`}
          variant="info"
          className="mb-3"
        />
        {loadingWithdraw ? (
          <Loader />
        ) : error ? (
          <Alert variant="danger">
            <strong>Error:</strong> {error}
          </Alert>
        ) : phase === 1 ? (
          <Form>
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>
          </Form>
        ) : phase === 2 ? (
          <Form>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Recipient's Phone Number</Form.Label>
              <div className="phone-number-input-container">
                <PhoneInput
                  international
                  defaultCountry="CA"
                  value={recipient.phoneNumber}
                  onChange={(value) =>
                    setRecipient({ ...recipient, phoneNumber: value })
                  }
                  placeholder="Enter phone number"
                  limitMaxLength
                />
              </div>
            </Form.Group>
            {currency === "GHS" || currency === "KES" ? (
              <Form.Group controlId="provider">
                <Form.Label>Provider</Form.Label>
                {providerLoading ? (
                  <Loader />
                ) : (
                  <Select
                    options={providerOptions}
                    value={providerOptions.find(
                      (option) => option.value === recipient.provider
                    )}
                    onChange={(selectedOption) =>
                      setRecipient({
                        ...recipient,
                        provider: selectedOption.value,
                      })
                    }
                    placeholder="Select provider"
                    isSearchable={true}
                  />
                )}
              </Form.Group>
            ) : null}
          </Form>
        ) : withdrawLoading ? (
          <Loader />
        ) : withdrawSuccess ? (
          <>
            <div className="lead">
              <Alert variant="success">
                <strong>Success:</strong> Please keep this transaction reference
                id for future: {withdrawResult.referenceId}
              </Alert>
            </div>
          </>
        ) : withdrawError ? (
          <Alert variant="danger">
            <strong>Error:</strong>
            {withdrawError.length > 0
              ? withdrawError
              : "Fialed to generate withdrawal link"}
          </Alert>
        ) : (
          <div>
            <p>
              <strong>Withdraw Amount:</strong> {amount} {currency}
            </p>
            <p>
              <strong>Recipient's Phone Number:</strong> {recipient.phoneNumber}
            </p>
            <p>
              <strong>Provider:</strong> {recipient.provider}
            </p>
            <Form.Group
              controlId="confirmCheckbox"
              className="mt-3 align-items-center"
            >
              <Form.Check
                type="checkbox"
                label="I confirm that I want to proceed with this withdrawal."
                checked={confirmed}
                onChange={() => setConfirmed(!confirmed)}
              />
            </Form.Group>
          </div>
        )}
        {phase !== 3 && (
          <Button variant="primary" onClick={handleNext} className="mt-3">
            Next
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        {loadingWithdraw || phase === 1 || error ? (
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        ) : phase === 2 ? (
          <Button variant="secondary" onClick={() => setPhase(1)}>
            Back
          </Button>
        ) : (
          <>
            {withdrawLoading ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => setPhase(2)}
                  disabled={true}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={handleWithdraw}
                  disabled={true}
                >
                  Withdraw
                </Button>
              </>
            ) : withdrawSuccess ? (
              <Button variant="secondary" onClick={() => onHide()}>
                Close
              </Button>
            ) : (
              <>
                <Button variant="secondary" onClick={() => setPhase(2)}>
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={handleWithdraw}
                  disabled={isWithdrawDisabled}
                >
                  Withdraw
                </Button>
              </>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawModal;
