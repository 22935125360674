import axios from 'axios';

const BASE_URL = '/api/users';

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/resetpassword`, {
      email,
    });
    return response.data; // Assuming response includes { message: 'Success message' }
  } catch (error) {
    throw error.response.data.error || 'An error occurred';
  }
};
