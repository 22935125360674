import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import LoginComponent from '../components/LoginOld/LoginComponent'
import { login } from '../actions/userActions'

const LoginScreen = ({ location, history }) => {

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo && userInfo.name) {
      history.push(redirect)
    }
    else if(userInfo && userInfo.status === "PENDING"){
      history.push('/verify')
    }
  }, [history, userInfo, redirect])

  const submitHandler = (email,password) => {
    // e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <div className="pt-50">
      {/* <FormContainer>
        <h1>Sign In</h1>
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary'>
            Sign In
          </Button>
        </Form>

        <Row className='py-3'>
          <Col>
            New Customer?{' '}
            <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
              Register
            </Link>
          </Col>
        </Row>
      </FormContainer> */}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <LoginComponent submitHandlerP={submitHandler}  />
    </div>
  )
}

export default LoginScreen
