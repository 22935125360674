import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import cendMateLogoWhite from '../assets/images/cendWhite.png'

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#0f1123', color: 'white', paddingTop: '150px', marginTop:'12vh', paddingBottom: '50px' }}>
      <Container>
        <Row>
          <Col xs={12} md={4} className="mb-4 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start ">
            <div className="d-flex flex-column text-center">
                <img src={cendMateLogoWhite} alt="Company Logo" width="150" className=" img-fluid text-center align-self-center" />
                <div className="lead ">Follow us on social media.</div>
                <div className="d-flex flex-row py-3 justify-content-center justify-content-md-start align-self-center">
                    <a href="https://www.facebook.com/cendmate?mibextid=LQQJ4d" target="_blank"><i className="fab fa-facebook-f mx-2"></i></a>
                    <a href="https://twitter.com/cendmate?s=21&t=0y1psN8wxycPKrf7m9zmxQ" target="_blank"><i className="fab fa-twitter mx-2"></i></a>
                    <a href="https://instagram.com/cend.mate?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fab fa-instagram mx-2"></i></a>
                </div>
            </div>
          </Col>
          <Col xs={6} md={4} className="mb-4 mb-md-0">
            <h5 style={{  color: 'inherit' }}>General Links</h5>
            <ul className="list-unstyled">
              <li><a href="#">About Us</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">FAQs</a></li>
              <li><a href="#">Support</a></li>
            </ul>
          </Col>
          <Col xs={6} md={4} className="mb-4 mb-md-0">
            <h5 style={{  color: 'inherit' }}>Legal Documents</h5>
            <ul className="list-unstyled">
              <li><a href="/terms-and-conditions" target="_blank">Terms and Conditions</a></li>
              <li><a href="/privacy-policy" target="_blank">Privacy Policy</a></li>
              <li><a href="/aml-policy" target="_blank">AML Policy</a></li>
            </ul>
          </Col>
        </Row>
        <hr style={{ borderColor: '#444' }} />
        <Row>
          <Col xs={12} className="text-center">
            <p className="text-muted small" style={{  color: 'inherit' }}>&copy; {new Date().getFullYear()} Cendmate. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
