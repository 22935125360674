import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Dropdown } from "react-bootstrap";
import "./Exchanger.css";

const Exchanger = () => {
  const [sourceAmount, setSourceAmount] = useState("");
  const [targetAmount, setTargetAmount] = useState("");
  const [sourceCurrency, setSourceCurrency] = useState("USD");
  const [targetCurrency, setTargetCurrency] = useState("NGN");
  const [searchText, setSearchText] = useState("");
  const [filteredCurrencies, setFilteredCurrencies] = useState(["USD", "CAD", "NGN"]);
  const [targetCurrencies, setTargetCurrencies] = useState([]);
  const [conversionRates, setConversionRates] = useState({});

  useEffect(() => {
    // Fetch conversion rates here and update conversionRates state
    let data = {
      "NGN": {
        "USD": 0.0012987012987012987,
        "CAD": 0.0017325974025974027
      },
      "KES": {
        "USD": 0.0015267175572519084,
        "CAD": 0.002036793893129771
      },
      "GHS": {
        "USD": 0.0018018018018018018,
        "CAD": 0.002403783783783784
      },
      "TZS": {
        "USD": 0.002197802197802198,
        "CAD": 0.002932087912087912
      },
      "USD": {
        "NGN": 763,
        "KES": 645,
        "GHS": 545,
        "TZS": 445
      },
      "CAD": {
        "NGN": 571.9211453414287,
        "KES": 483.4720035979312,
        "GHS": 408.5151038153062,
        "TZS": 333.5582040326812
      }
    }
    setConversionRates(data);
    setFilteredCurrencies(Object.keys(data));
    setTargetCurrencies(Object.keys(data));
  }, []);

  const handleSourceAmountChange = (event) => {
    const value = event.target.value;
    setSourceAmount(value);
    setTargetAmount( (value * conversionRates[sourceCurrency][targetCurrency]).toFixed(2) );
  };

  const handleTargetAmountChange = (event) => {
    const value = event.target.value;
    setTargetAmount(value);
    setSourceAmount( (value / conversionRates[sourceCurrency][targetCurrency]).toFixed(2) );
  };

  const handleSourceCurrencyChange = (event) => {
    const value = event.target.value;
    setSourceCurrency(value);
    setTargetAmount( (sourceAmount * conversionRates[value][targetCurrency]).toFixed(2) );
    //reset the list to the KEYs of Source coountry, ex NGN can only have CAD and USD
    setTargetCurrencies(Object.keys(conversionRates[value]));
    //rest the target country by default to the first option
    setTargetCurrency(Object.keys(conversionRates[value])[0])
  };

  const handleTargetCurrencyChange = (event) => {
    const value = event.target.value;
    setTargetCurrency(value);
    setTargetAmount( (sourceAmount * conversionRates[sourceCurrency][value]).toFixed(2) );
  };

  const handleSearchTextChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    const filtered = Object.keys(conversionRates).filter((currency) =>
      currency.includes(value.toUpperCase())
    );
    setFilteredCurrencies(filtered);
  };

  return (
    <div className="">
      <Container className="h-100">
        <div className="row justify-content-between">
          <div className="col-md-2">
            <div className="h4 ">Exchange</div>
          </div>
        </div>
        <Row className="">
          {/* From  Section */}
          <Col>
            {/* <Form.Label>Amount ({sourceCurrency})</Form.Label> */}
            <div className="d-flex flex-row ">
              <div className="left-box ">
                <Form.Group controlId="fromCurrency">
                  <Form.Label className="box-label">You send</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0.00"
                    value={sourceAmount}
                    onChange={handleSourceAmountChange}
                    className="input-field"
                  />
                </Form.Group>
              </div>
              <div className="right-box w-50">
                <Form.Group controlId="fromCurrency">
                  <Form.Label className="box-label">Currency</Form.Label>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdown-field"
                      variant="light"
                      id="dropdown-basic"
                    >
                      {sourceCurrency ? sourceCurrency : "Select Currency"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        className="mb-2"
                      />
                      {filteredCurrencies.map((currency) => (
                        <Dropdown.Item
                          key={currency}
                          onClick={() =>
                            handleSourceCurrencyChange({
                              target: { value: currency },
                            })
                          }
                        >
                          {currency}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </div>
            </div>
          </Col>
          {/* To section */}
          <Col className="mt-2 mt-md-0">
            <Form.Group>
              {/* <Form.Label>Amount ({targetCurrency})</Form.Label> */}
              <div className="d-flex flex-row ">
                <div className="left-box ">
                  <Form.Group controlId="toAmount">
                    <Form.Label className="box-label">They recieve</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      value={targetAmount}
                      onChange={handleTargetAmountChange}
                      className="input-field"
                    />
                  </Form.Group>
                </div>
                <div className="right-box w-50">
                  <Form.Group controlId="toCurrency">
                    <Form.Label className="box-label">Currency</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdown-field"
                        variant="light"
                        id="dropdown-basic"
                      >
                        {targetCurrency ? targetCurrency : "Select Currency"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={searchText}
                          onChange={handleSearchTextChange}
                          className="mb-2"
                        />
                        {targetCurrencies.map((currency) => (
                          <Dropdown.Item
                            key={currency}
                            onClick={() =>
                              handleTargetCurrencyChange({
                                target: { value: currency },
                              })
                            }
                          >
                            {currency}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Exchanger;
