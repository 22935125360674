import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./TimeLine.css";

const TimeLine = () => {
  return (
    <div className="timeline-container">
      <Container>
        <Row className="timeline">
          <Col xs={12} md={4} className="timeline__step">
            <div className="timeline__step__icon timeline__step__icon-1">
            <i className="fas fa-user-plus mx-2" />
            </div>
            <div className="timeline__step__title">1. SIGN UP</div>
            <div className="timeline__step__description">
              CREATE A FREE CENDMATE ACCOUNT.
            </div>
          </Col>
          <Col xs={12} md={4} className="timeline__step">
            <div className="timeline__step__icon timeline__step__icon-2">
            <i className="fas fa-id-card mx-2" />
            </div>
            <div className="timeline__step__title">2. GET VERIFIED</div>
            <div className="timeline__step__description">
              VERIFY YOUR IDENTITY.
            </div>
          </Col>
          <Col xs={12} md={4} className="timeline__step">
            <div className="timeline__step__icon timeline__step__icon-3">
            <i className="fas fa-wallet mx-2" />
            </div>
            <div className="timeline__step__title">3. SPEND</div>
            <div className="timeline__step__description">
              FUND YOUR WALLET VIA INTERAC AND SPEND.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TimeLine;
