import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Image} from 'react-bootstrap';
import './WalletScreen.css'
import Message from '../../components/Message'
import TransactionTable from '../../components/TransactionTable/TransactionTable'
import Loader from '../../components/Loader';
import DepositModal from '../../components/DepositModal/DepositModal';
import WithdrawModal from '../../components/WithdrawModal/WithdrawModal';
import WithdrawModalNGN from '../../components/WithdrawModalNGN/WithdrawModalNGN';
import { getWalletList } from '../../actions/walletActions';
import { generatePayInLink } from '../../services/paymentService';

const WalletScreen = ({ match, history }) => {

  const dispatch = useDispatch();
  const walletId = match.params.id ;

  const [walletError, setWalletError] = useState(null);
  const [foundWallet, setFoundWallet] = useState(null);
  const [showAll,setShowAll]=useState(false);

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo } = userVerify

  const walletList = useSelector((state) => state.walletList)
  const { loading:walletsLoading, error:walletsError, wallets } = walletList

  // Deposit Modal Area

  const [showDepositModal, setShowDepositModal] = useState(false);
  const [walletClosed, setWalletClosed] = useState(false);
  const [loadingPayIn, setLoadingPayIn] = useState(false);
  const [errorPayIn, setErrorPayIn] = useState(null);
  const [payInLink, setPayInLink] = useState(null);

  const handleDeposit = async (amount) => {
    try {
      setLoadingPayIn(true);

      // Call the generatePayInLink function from paymentService.js
      const generatedLink = await generatePayInLink(userInfo.token, foundWallet, amount);

      // If successful, update the state with the generated pay-in link
      setPayInLink(generatedLink);
    } catch (error) {
      console.log(error)
      error.response.data.error?setErrorPayIn(error.response.data.error):setErrorPayIn("Error calling the API, please try again at a later time or contact support.");
      // Handle any errors here (e.g., display an error message)
    } finally {
      setLoadingPayIn(false);
    }
  };

  //WithdrawModal Area
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showWithdrawNGNModal, setShowWithdrawNGNModal] = useState(false);


  function addCommasToNumber(num) {
    if (typeof num !== 'number') {
      return 'Invalid input. Please enter a number.';
    }
  
    // Round the number to 2 decimal places
    const roundedNum = Math.round(num * 100) / 100;
  
    // Convert the rounded number to a string and split it into integer and decimal parts
    const [integerPart, decimalPart] = roundedNum.toString().split('.');
  
    // Add commas to the integer part
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    // Return the formatted number with 2 decimal places
    if (decimalPart) {
      return `${integerWithCommas}.${decimalPart.padEnd(2, '0')}`;
    } else {
      return `${integerWithCommas}.00`;
    }
  }
  
  useEffect(() => {
    if (userInfo && userInfo !== null) {
      dispatch(getWalletList());
    } else {
      history.push('/login');
    }
  }, [dispatch, history, userInfo]);

  // ONLY reload the page to Wipe existing Modal data when closed
  useEffect(() => {
    if(walletClosed === true)
    window.location.reload();
  }, [walletClosed]);

  // Dispatch getWalletList and then check for the wallet existence
  useEffect(() => {
    if (wallets.length > 0) {
      const wallet = wallets.find((wallet) => wallet._id === walletId);
      if (wallet) {
        setFoundWallet(wallet);
      } else {
        setWalletError('Error! Cannot find Wallet, Please contact admin.');
      }
    }
  }, [wallets, walletId, walletClosed]);

  const handleGoBack = () => {
    history.goBack();
  };

  //transactions
  const transactions = []
  

  return (
    
    <div className="h-100">
        <section className='banner-area pt-100'>
        </section>
      {loading||walletsLoading ? (
      <Loader />
    ) : (error || walletError || walletsError) ? (
      <Message variant='danger'>{error||walletError}</Message>
    ) : userInfo && userInfo !=null ? (
      <>
      
        <div className="container">
          <div className="row">
          {foundWallet?(
            <>
                    <div className="row justify-content-center py-4">
                        {/* Container */}
                        <div className="col-lg-8">
                            {/* Walet Details Top Section */}
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                    <div className="btn btn-lg btn-primary my-3 font-small" onClick={handleGoBack}><i className="fa fa-arrow-left mx-2"></i>Back</div>
                                        <div className="d-flex flex-row align-items-center">
                                            <div className="">
                                                <Image
                                                    src={`/images/flags/${foundWallet.currency}.png`}
                                                    alt={`${foundWallet.currency} flag`}
                                                    width={60}
                                                    height={60}
                                                />
                                            </div>
                                            <div className="h5 mx-2">{foundWallet.currency} Wallet</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row py-4">
                                    <div className="col-md-6 px-4">
                                        <div className="h1">{addCommasToNumber(foundWallet.amount)} {foundWallet.currency}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-row">
                                            <div className="d-flex flex-column text-center mx-3">
                                                <div className="icon-holder-circle"
                                                    onClick={() => setShowDepositModal(true)}
                                                    style={{ cursor: 'pointer' }}
                                                    >
                                                    <Image
                                                      src={`/images/plus-thin.png`}
                                                      alt={`Open Account`}
                                                      width={40}
                                                      height={40}
                                                    />
                                                </div>
                                                <div className="h5 py-2">Add</div>
                                            </div>
                                            <div className="d-flex flex-column text-center mx-3">
                                                <div className="icon-holder-circle">
                                                    <Image
                                                      src={`/images/convert.png`}
                                                      alt={`Open Account`}
                                                      width={40}
                                                      height={40}
                                                    />
                                                </div>
                                                <div className="h5 py-2">Convert</div>
                                            </div>
                                            <div className="d-flex flex-column text-center mx-3">
                                                <div className="icon-holder-circle" 
                                                    onClick={()=>{foundWallet.currency === 'NGN'?setShowWithdrawNGNModal(true):setShowWithdrawModal(true)}}
                                                    style={{cursor:'pointer'}}>
                                                    <Image
                                                          src={`/images/arrow-up.png`}
                                                          alt={`Open Account`}
                                                          width={40}
                                                          height={40}
                                                      />
                                                </div>
                                                <div className="h5 py-2">Send</div>
                                            </div>
                                            <div className="d-flex flex-column text-center mx-3">
                                                <div className="icon-holder-circle">
                                                    <Image
                                                      src={`/images/arrow-down.png`}
                                                      alt={`Open Account`}
                                                      width={40}
                                                      height={40}
                                                    />
                                                </div>
                                                <div className="h5 py-2">Recieve</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* transaction table */}
                          <section className='pb-4'>
                            <div className="row border-top py-4">
                                    <div className="col-6">
                                        <div className="h3">Transactions</div>
                                    </div>
                                    <div className="col-6 justify-content-end">
                                        <div className="d-flex flex-column justify-content-center align-items-end">
                                        <p onClick={()=>{setShowAll(!showAll)}} style={{textDecoration:'underline', cursor:'pointer'}} className='h5 px-1'>{showAll===true?"Show Less":"Show More"}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row ">
                                {/* <TransactionTable transactions={transactions} addCommasToNumber={addCommasToNumber}/> */}
                                <TransactionTable type="wallet" userToken={userInfo.token} walletId={foundWallet._id}  showAll={showAll}/>
                                </div>
                          </section>
                        </div>
                    </div>
            </>
          ):(null)}
          </div>
            <DepositModal
              show={showDepositModal}
              onHide={() => {setWalletClosed(!walletClosed);setShowDepositModal(false)}}
              onDeposit={handleDeposit}
              loadingPayIn={loadingPayIn}
              payInLink={payInLink}
              currency={foundWallet && foundWallet.currency}
              foundWallet = {foundWallet}
              error={errorPayIn}
            />
            <WithdrawModal
              show={showWithdrawModal}
              onHide={() => setShowWithdrawModal(false)}
              currency={foundWallet && foundWallet.currency}
              wallet = {foundWallet}
              userToken = {userInfo.token}
            />
            <WithdrawModalNGN
              show={showWithdrawNGNModal}
              onHide={() => setShowWithdrawNGNModal(false)}
              currency={foundWallet && foundWallet.currency}
              wallet = {foundWallet}
              userToken = {userInfo.token}
            />

        </div>
      </>
    ):(
      <div className="lead">Sorry You are Not Authorized, Please log out and log back in!</div>
    )}
    </div>
  )
}

export default WalletScreen
