import axios from 'axios';

// Function to generate a pay-in link
export const generatePayInLink = async (userToken, wallet, amount) => {
  try {
    // Prepare the request payload
    const requestBody = {
      wallet,
      amount,
    };

    // Set headers with the user token
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };

    // Make the POST request to the pay-in endpoint
    const response = await axios.post(`/api/payments/pay-in`, requestBody, { headers });
    console.log("payCode:"+response.data.payCode)
    // Return the pay-in link from the response
    return response.data.payInLink;
  } catch (error) {
    // Handle errors here (e.g., network errors, API errors)
    throw error;
  }
};

// Function to initiate a withdrawal
export const initiateWithdraw = async (userToken, wallet, amount, recipient) => {
  try {
    // Prepare the request payload
    const requestBody = {
      wallet,
      amount,
      accountNumber:recipient.phoneNumber,
      mobileMoneyCode:recipient.provider,
    };

    // Set headers with the user token
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };

    // Make the POST request to the withdrawal endpoint
    const response = await axios.post(`/api/payments/pay-out`, requestBody, { headers });

    // Return the response data, which may include a reference ID
    return response.data;
  } catch (error) {
    // Handle errors here (e.g., network errors, API errors)
    throw error;
  }
};
