import axios from 'axios';

const BASE_URL = '/api/provider'; // Change this if your API route is different

const getProviderList = async (currency) => {
  try {
    const response = await axios.post(`${BASE_URL}`, { currency });
    const providers = response.data.data.map((provider) => ({
      value: provider.value,
      label: provider.name,
    }));
    return providers;
  } catch (error) {
    // Handle errors here (e.g., show an error message or log the error)
    console.error('Error fetching providers:', error);
    throw error;
  }
};

export default getProviderList;
