import axios from 'axios'
import {
  EXCHANGE_DETAILS_FAIL,
  EXCHANGE_DETAILS_REQUEST,
  EXCHANGE_DETAILS_SUCCESS
} from '../constants/exchangeConstants'


export const getExchangeDetails = (from,to) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXCHANGE_DETAILS_REQUEST,
    })

    console.log("Calling from:"+from+", to:"+to)
    const { data } = await axios.get(`https://api.exchangerate.host/convert?from=${from}&to=${to}`)
    

    dispatch({
      type: EXCHANGE_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    
    dispatch({
      type: EXCHANGE_DETAILS_FAIL,
      payload: message,
    })
  }
}




