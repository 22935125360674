import {
    WALLET_LIST_FAIL,
    WALLET_LIST_REQUEST,
    WALLET_LIST_RESET,
    WALLET_LIST_SUCCESS
  } from '../constants/walletConstants'
  
  
  
  export const walletListReducer = (state = { wallets: [] }, action) => {
    switch (action.type) {
      case WALLET_LIST_REQUEST:
        return { ...state, loading: true }
      case WALLET_LIST_SUCCESS:
        return { loading: false, wallets: action.payload }
      case WALLET_LIST_FAIL:
        return { loading: false, error: action.payload }
      case WALLET_LIST_RESET:
        return { wallets: [] }
      default:
        return state
    }
  }