import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'
import { LinkContainer } from 'react-router-bootstrap'
import "./Header.css";
import cendMateLogoWhite from '../../assets/images/cendWhite.png'
import cendMateLogoBlack from '../../assets/images/cendBlack.png'

function Header() {
  
  const dispatch = useDispatch()
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const userVerify = useSelector((state) => state.userVerify)
  const { userInfo } = userVerify

  const closeMenu = () => {
    console.log(scrollPosition) //just to use scrollPosition value
    setIsMenuOpen(false)
  }
  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
      setIsScrolled(window.scrollY > 0);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <header className={`Header ${isScrolled ? "scrolled" : ""} align-items-center`}>
      <nav>
        <LinkContainer to='/'>
        <div className="logo">
          {isScrolled?(<img className="logo-pic" src={cendMateLogoBlack} alt="" />):
          (<img className="logo-pic" src={cendMateLogoWhite} alt="" />)}
        </div>
        </LinkContainer>
        {userInfo?(
        <ul>
          <li>
            <LinkContainer to='/'>
              <a className="">Home</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/dashboard'>
              <a className="">Dashboard</a>
            </LinkContainer>
          </li>
          
          <li>
            <LinkContainer to='/exchange'>
              <a className="">Convert Currency</a>
            </LinkContainer>
          </li>
          {userInfo && userInfo.isAdmin?(
            <li>
            <div className="dropdown">
              <a className="dropbtn">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  Admin Links
                  <i className="fas fa-caret-down mx-2"></i>
                </div>
              </a>
              <div className="dropdown-content">
                <LinkContainer to='/admin/userList'>
                  <a>Users</a>
                </LinkContainer>
                <LinkContainer to='/admin/wallets'>
                  <a>Wallets</a>
                </LinkContainer>
                <LinkContainer to='/currencies'>
                  <a>Currencies</a>
                </LinkContainer>
              </div>
            </div>
          </li>
          ):(null)}
          <li>
            <div className="dropdown">
              <a className="dropbtn">
                <div className="d-flex flex-row justify-content-center align-items-center">
                <i className="fas fa-user mx-2"></i>
                  {userInfo.fName + " " + userInfo.lName}
                  <i className="fas fa-caret-down mx-2"></i>
                </div>
              </a>
              <div className="dropdown-content">
                <LinkContainer to='/profile'>
                  <a>
                    <div className="d-flex flex-row align-items-center">
                      <i className="fas fa-address-card mx-2"></i>
                      Profile
                    </div>
                  </a>
                </LinkContainer>
                <LinkContainer to='/transactions'>
                  <a>
                    <div className="d-flex flex-row align-items-center">
                      <i className="fas fa-receipt mx-2"></i>
                      Transactions
                    </div>
                  </a>
                </LinkContainer>
                <a href="#" onClick={logoutHandler} >
                  <div className="d-flex flex-row align-items-center">
                    <i className="fas fa-sign-out-alt mx-2"></i>
                    Logout
                  </div>
                </a>
                
              </div>
            </div>
          </li>
        </ul>):(
          // if the user is not logged in
        <ul>
          <li>
            <LinkContainer to='/'>
              <a className="">Home</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/login'>
              <a className="">Login</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/about'>
              <a className="">About</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/register'>
              <div className={`menu-button ${isScrolled ? "scrolled" : ""}`}>Sign Up</div>
            </LinkContainer>
          </li>
        </ul>)}
        
      </nav>
      <div
        className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className={`line ${isScrolled ? "scrolled" : ""}`}></div>
        <div className={`line ${isScrolled ? "scrolled" : ""}`}></div>
        <div className={`line ${isScrolled ? "scrolled" : ""}`}></div>
      </div>
      
      {userInfo?(
        <ul className={`menu ${isMenuOpen ? "open" : ""}`}>
          <li>
            <LinkContainer to='/' onClick={closeMenu}>
              <a className="">Home</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/dashboard' onClick={closeMenu}>
              <a className="">Dashboard</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/exchange' onClick={closeMenu}>
              <a className="">Convert Currency</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/profile' onClick={closeMenu}>
              <a className="">Profile</a>
            </LinkContainer>
          </li>
          {userInfo && userInfo.isAdmin?(<li>
            <LinkContainer to='/users' onClick={closeMenu}>
              <a className="">Admin Links</a>
            </LinkContainer>
          </li>):(null)}
          <li onClick={logoutHandler}>
            <div className={`menu-button scrolled` }>Logout</div>
          </li>
        </ul>):(
          // if the user is not logged in
        <ul className={`menu ${isMenuOpen ? "open" : ""}`}>
          <li>
            <LinkContainer to='/' onClick={closeMenu}>
              <a className="">Home</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/login' onClick={closeMenu}>
              <a className="">Login</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/about' onClick={closeMenu}>
              <a className="">About</a>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to='/register' onClick={closeMenu}>
              <div className={`menu-button scrolled`}>Sign Up</div>
            </LinkContainer>
          </li>
        </ul>)}
    </header>
  );
}

export default Header;
