import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import cendMateLogoBlack from '../../assets/images/cendBlack.png';
import './ResetPassword.css';
import { resetPassword } from '../../services/userService';

const ResetPassword = ({ history }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await resetPassword(email);
      setShowSuccessAlert(true);
      setErrorMessage('');
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (error) {
      setErrorMessage(error);
      setShowSuccessAlert(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-100">
      <Container>
        {errorMessage && <Message variant="danger">{errorMessage}</Message>}
        {showSuccessAlert && (
          <Message variant="success">Password reset request successful. Redirecting...</Message>
        )}
      </Container>

      {loading ? (
        <Loader />
      ) : (
        <Container className="d-flex justify-content-center align-items-center h-100 ">
          <Row className="justify-content-center">
            <Col md={10} lg={6}>
              <Card className="p-4 shadow">
                <Row className="mb-4">
                  <Col className="d-flex justify-content-center align-items-center">
                    <div className="rounded-circle border border-primary d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px' }}>
                      <img src={cendMateLogoBlack} alt="profile" style={{ width: '70%', height: '65%' }} />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-center">
                  <Col>
                    <h4 className="fw-bold mb-0 text-primary">Reset Password</h4>
                    <p>Enter your email below. We'll send a temporary password for logging in to your account. Note: This password allows a single login and requires manual reset from the profile screen.</p>
                  </Col>
                </Row>
                <Form onSubmit={submitHandler}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Reset Password
                  </Button>
                </Form>
                <Row className="mt-4">
                  <Col className="text-end">
                    <a href="/login">Back to Sign In</a>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default ResetPassword;
