import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = ({ title }) => {
  return (
    <div className="text-center">
      <Spinner
        animation='border'
        role='status'
        style={{
          width: '100px',
          height: '100px',
          margin: 'auto',
          display: 'block',
        }}
      >
        <span className='sr-only'>Loading...</span>
      </Spinner>
      {title && <p className='pt-3'>{title}</p>}
    </div>
  )
}

export default Loader
