import axios from 'axios';

// Function to fetch transactions for a specific wallet by wallet ID
export const getWalletTransactions = async (walletId, userToken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };
    const response = await axios.get(`/api/transactions/wallet/${walletId}`, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to fetch transactions for the logged-in user's profile
export const getUserTransactions = async (userToken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };
    const response = await axios.get(`/api/transactions/profile`, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to fetch all transactions (admin only)
export const getAllTransactions = async (userToken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };
    const response = await axios.get(`/api/transactions`, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to submit a new transaction
export const submitTransaction = async (transactionData, userToken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };
    const response = await axios.post(`/api/transactions/submit`, transactionData, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to update a transaction by ID
export const updateTransaction = async (transactionId, transactionData, userToken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };
    const response = await axios.put(`/api/transactions/${transactionId}`, transactionData, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to delete a transaction by ID
export const deleteTransaction = async (transactionId, userToken) => {
  try {
    const headers = {
      'Authorization': `Bearer ${userToken}`,
    };
    const response = await axios.delete(`/api/transactions/${transactionId}`, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
