import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import './dashboard.css'
import Message from '../../components/Message'
import Wallet from '../../components/Wallet/Wallet'
import Loader from '../../components/Loader'
import bnbIcon from '../../assets/images/bnbPic.svg'
import btcIcon from '../../assets/images/btcPic.svg'
import TransactionTable from '../../components/TransactionTable/TransactionTable'
import WalletTable from '../../components/WalletTable/WalletTable'
import { getWalletList } from '../../actions/walletActions'

const Dashboard = ({ location, history }) => {

  const dispatch = useDispatch()
  const walletSuccess = location.state && location.state.successMessage || null;

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo } = userVerify

  const walletList = useSelector((state) => state.walletList)
  const { loading:walletsLoading, error:walletsError, wallets } = walletList

  const [showAll,setShowAll]=useState(false);

  //transactions
  // const transactions = [
  //   {
  //     _id: 1,
  //     user: {
  //       fName: 'John',
  //       lName: 'Doe',
  //     },
  //     type: 'pay-out',
  //     currency: 'USD',
  //     amount: 1000,
  //     amountSettled: 900,
  //     fromWallet: {
  //       currency: 'USD',
  //     },
  //     referenceId: 'ABC123',
  //     createdAt: '2022-01-01T00:00:00Z',
  //   },
  //   {
  //     _id: 2,
  //     user: {
  //       fName: 'Jane',
  //       lName: 'Smith',
  //     },
  //     type: 'transfer',
  //     currency: 'CAD',
  //     amount: 2000,
  //     amountSettled: 1680,
  //     fromWallet: {
  //       currency: 'CAD',
  //     },
  //     referenceId: 'DEF456',
  //     createdAt: '2022-01-02T00:00:00Z',
  //   },
  //   {
  //     _id: 3,
  //     user: {
  //       fName: 'Bob',
  //       lName: 'Johnson',
  //     },
  //     type: 'pay-out',
  //     currency: 'EUR',
  //     amount: 500,
  //     amountSettled: 450,
  //     fromWallet: {
  //       currency: 'EUR',
  //     },
  //     referenceId: 'GHI789',
  //     createdAt: '2022-01-03T00:00:00Z',
  //   },
  //   {
  //     _id: 4,
  //     user: {
  //       fName: 'Alice',
  //       lName: 'Williams',
  //     },
  //     type: 'pay-in',
  //     currency: 'USD',
  //     amount: 1500,
  //     amountSettled: 1500,
  //     fromWallet: {
  //       currency: 'USD',
  //     },
  //     referenceId: 'JKL012',
  //     createdAt: '2022-01-04T00:00:00Z',
  //   },
  //   {
  //     _id: 5,
  //     user: {
  //       fName: 'Mike',
  //       lName: 'Brown',
  //     },
  //     type: 'transfer',
  //     currency: 'EUR',
  //     amount: 750,
  //     amountSettled: 274,
  //     fromWallet: {
  //       currency: 'EUR',
  //     },
  //     referenceId: 'MNO345',
  //     createdAt: '2022-01-05T00:00:00Z',
  //   },
  // ];
  
  
  // wallet limits
  const walletLimits = [
    {
      "id": 1,
      "currency": "USD",
      "withdrawalLimit": 1000,
      "depositLimit": 5000
    },
    {
      "id": 2,
      "currency": "EUR",
      "withdrawalLimit": 800,
      "depositLimit": 4000
    },
    {
      "id": 3,
      "currency": "GBP",
      "withdrawalLimit": 1200,
      "depositLimit": 6000
    }
  ];  
  
  function addCommasToNumber(num) {
    if (typeof num !== 'number') {
      return 'Invalid input. Please enter a number.';
    }
  
    // Round the number to 2 decimal places
    const roundedNum = Math.round(num * 100) / 100;
  
    // Convert the rounded number to a string and split it into integer and decimal parts
    const [integerPart, decimalPart] = roundedNum.toString().split('.');
  
    // Add commas to the integer part
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    // Return the formatted number with 2 decimal places
    if (decimalPart) {
      return `${integerWithCommas}.${decimalPart.padEnd(2, '0')}`;
    } else {
      return `${integerWithCommas}.00`;
    }
  }
  
  useEffect(() => {
    if (userInfo && userInfo !== null) {
        //do something
        dispatch(getWalletList())
      } else {
        history.push('/login')
      }
  }, [dispatch, history, userInfo])

  return (
    <div className="h-100">
      <section className='banner-area pt-100'></section>
      {loading || walletsLoading ? (
      <Loader />
    ) : error || walletsError ? (
      <Message variant='danger'>{error}</Message>
    ) : userInfo && userInfo !=null ? (
      <>
        <div className="container">
          <div className="row">
          <div className="container">
              <div className="row">
                <div className="col-md-12">
                <section className=''> 
                    <div className="container-fluid  pt-4  ">
                      <div className="row justify-content-center">
                        {/* Dashboard Stats Pane */}
                        <div className="col-lg-10">
                        {walletSuccess?(<Message variant='success' dismiss={true}>Wallet Successfully Added!</Message>):null}
                          <div className="row">
                            <div className="h3 font-poppins">Welcome {userInfo.name} , <br/>
                              <div className="lead font-weight-normal">Ready to build your own portfolio today!</div>
                            </div>
                          </div>
                          <section>
                            <div className="row pt-4">
                                <div className="h3">Your Wallets</div>
                            </div>
                            <div className="pb-4">
                              {/* Wallet Display section begins */}
                            {wallets && wallets.length>0?(
                              // If the wallets length is greater than 0 then display the wallets
                              <>
                              <div className="row py-2">
                                {/* Wallet Column */}
                                {wallets.map((wallet)=>(
                                  <LinkContainer to={`/wallet/${wallet._id}`}>
                                    <div className="col-6 col-md-4 col-lg-3 mb-2">
                                    <Wallet wallet={wallet} addCommasToNumber={addCommasToNumber}/>
                                    </div>
                                  </LinkContainer>
                                ))}
                                <div className="col-6 col-md-4 col-lg-3 mb-2">
                                    <LinkContainer to={`/registerWallet`}>
                                      <div>
                                      <Wallet blank={true} addCommasToNumber={addCommasToNumber}/>
                                      </div>
                                    </LinkContainer>
                                </div>
                              </div>
                              </>
                            ):(
                            // if there are no wallets, then prompt the user to create one
                            <>
                              <div className="row py-2">
                                <div className="col-6 col-md-4 col-lg-3 mb-2">
                                  <LinkContainer to={`/registerWallet`}>
                                    <div>
                                      <Wallet blank={true} />
                                    </div>
                                  </LinkContainer>
                                </div>
                              </div>
                            </>)}
                            {/* Wallet Display section ends */}
                            </div>
                          </section>
                          
                          {/* transaction table */}
                          <section className='pb-4'>
                          <div className="row border-top py-4">
                                <div className="col-6">
                                    <div className="h3">Transactions</div>
                                </div>
                                <div className="col-6 justify-content-end">
                                    <div className="d-flex flex-column justify-content-center align-items-end">
                                        <p onClick={()=>{setShowAll(!showAll)}} style={{textDecoration:'underline', cursor:'pointer'}} className='h5 px-1'>{showAll===true?"Show Less":"Show More"}</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row ">
                              {/* <TransactionTable transactions={transactions} addCommasToNumber={addCommasToNumber}/> */}
                              <TransactionTable type="user" userToken={userInfo.token} showAll={showAll} />
                            </div>
                          </section>

                          {/* Daily Limits section */}
                          <section className='pb-4'>
                            <div className="row border-top py-4">
                              <div className="h3">Daily Limits</div>
                            </div>
                            <WalletTable wallets={walletLimits} addCommasToNumber={addCommasToNumber}/>
                          </section>
                        </div>

                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div> 

          </div>
        </div>
      </>
    ):(
      <div className="lead">Sorry You are Not Authorized, Please log out and log back in!</div>
    )}
    </div>
  )
}

export default Dashboard
